<template>
	<el-dialog title="温馨提示" :visible.sync="dialogVisible" width="500px" :append-to-body="true">
		<div class="prompt">
			<div class="avatar">
				<p class="title">图片样例：</p>
				<img class="img" src="@/assets/avatar.png"/>
			</div>
			<div class="desc">
				<p class="title">照片要求：</p>
				<ul class="demand">
					<li>1、需要近期免冠清晰照片</li>
					<li>2、照片高度比为 5 : 4</li>
					<li>3、不大于 300KB</li>
					<li>4、支持格式 jpg、png</li>
				</ul>
			</div>
		</div>
		<div slot="footer" class="footer">
			<el-button size="small" type="success" @click="upload" class="btn">上传头像</el-button>
		</div>
	</el-dialog>
</template>

<script>
export default {
	data() {
		return {
			dialogVisible: false
		}
	},
	methods: {
		upload(){
			this.dialogVisible = false
			this.$emit('upload')
		}
	}
};
</script>

<style scoped lang="less">
.prompt {
	display: flex;
	margin: 0 63px;

	.title {
		font-size: 14px;
		margin-bottom: 10px;
	}

	.avatar {
		flex: 2;
		margin-right: 10px;

		.img {
			width: 80px;
			height: 113px;
		}
	}

	.desc {
		flex: 3;
		font-size: 16px;

		.demand>* {
			margin-bottom: 12px;
		}
	}
}

.footer {
	margin-top: -30px;
	margin:0 auto;
	text-align: center;
	
	.btn {
		background-color: #008B61;
	}
}
</style>