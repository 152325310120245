import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import persistedState from 'vuex-persistedstate';

import getters from './getters';

import common from './modules/common';
import status from './modules/status';

export default new Vuex.Store({
	modules: {
		common,
        status
	},
	getters,
	plugins: [
        persistedState({
        //     //默认使用localStorage来固化数据，也可使用sessionStorage，配置一样
            storage: window.localStorage,
            reducer(val) {
                return {
                    // 只储存state中的值
                    // user: {
					// 	token: val.user.token,
                    //     source: val.user.source,
					// }
                    common: {
                        token: val.common.token,
                        userInfo: val.common.userInfo,
                        // configMap: val.common.configMap,     没必要做缓存
                        permissions: val.common.permissions
                    },
                    status: {
                        contrastUserList: val.status.contrastUserList,
                        list1: val.status.list1,
                        list2: val.status.list2,
                        list3: val.status.list3,
						menuValue: val.status.menuValue
                    }
                }
            }
        })
    ]
})
