<template>
	<div class="table-form" :class="{'detail': isDetail}">
		<div class="title">{{ title }}</div>
		<table border="1">
			<tr v-for="(header,index) in annexHeaders" :key="index">
				<td>{{header.label}}</td>
				<td>
					<FileList :files="form[header.label]" :isDetail="isDetail" :fileList="fileList" :header="header"></FileList>
				</td>
			</tr>
		</table>
	</div>
</template>

<script>
import FileList from "./FileList.vue";

export default {
  components: {
    FileList,
  },
  props: {
    isDetail: {
      type: Boolean,
      default: true,
    },
    fileList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {},
  watch: {
    // fileList.length是新增或删除文件，此时fileList的引用未变
    "fileList.length": {
      handler() {
        this.annexHeaders.forEach((annex) => {
          this.form[annex.label] = this.fileList.filter(
            (file) => file.dirName === annex.label
          );
		  this.form[annex.label].sort((a, b) => a?.sn - b?.sn);
		  this.form[annex.label].forEach((file, i) => file.sn = i + 1)
        });
      },
    },
    // fileList是在修改时，拖动了文件，重新请求数据后，fileList的引用已变
    fileList(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.annexHeaders.forEach((annex) => {
          this.form[annex.label] = this.fileList.filter(
            (file) => file.dirName === annex.label
          );
          this.form[annex.label].sort((a, b) => a?.sn - b?.sn);
        });
      }
    },
  },
  data() {
    return {
      dirName: "",
      title: "",
      hrmsDir: [],
      annexHeaders: {},
      form: {},
    };
  },
  created() {
    this.getTitle();
    this.getDir();
    this.getAnnexHeaders();
    this.createForm();
  },
  methods: {
    createForm() {
      this.annexHeaders.forEach((annex) => {
        this.$set(this.form, annex.label, []);
        this.form[annex.label] = this.fileList.filter(
          (file) => file.dirName === annex.label
        );
        this.form[annex.label].sort((a, b) => a?.sn - b?.sn);
      });
    },
    getTitle() {
      const lib = this.$getCurrentLib();
      if (lib) {
        this.title = lib === "cadre" ? "成长档案" : "培养档案";
      } else {
        this.title = "附件材料";
      }
    },
    getDir() {
      const path = this.$route.path;
      if (path.endsWith("/cadre")) {
        this.hrmsDir = this.$store.getters.dictMap["hrms_dir_cadre"];
      } else if (path.endsWith("/dean")) {
        // return this.$store.getters.dictMap['hrms_dir_dean'];
        const dir = this.$store.getters.dictMap["hrms_dir_dean"];
        const index = dir.findIndex((item) => item.label === "基础信息表");
        if (index) {
          dir.splice(1, 0, dir.splice(index, 1)[0]);
        }
        this.hrmsDir = dir;
      } else {
        this.hrmsDir = this.$store.getters.dictMap["hrms_dir"];
      }
    },
    getAnnexHeaders() {
      this.annexHeaders = this.hrmsDir.map((item) => {
        return {
          label: item.label,
          value: item.id,
        };
      });
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
td:nth-child(1) {
  width: 15%;
  background-color: #f0f2f5;
}
td:nth-child(2) {
  padding: 10px 10px;
}
.row {
  display: block;
  width: 420px;
  .file {
    height: 26px;
    line-height: 26px;
    color: #606266;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .idx {
      width: 30px;
    }

    .left {
      padding-right: 12px;
      width: calc(100% - 30px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .name:hover {
        color: #409eff;
        cursor: pointer;
      }
      i {
        margin-right: 5px;
      }
    }
    .close {
      display: none;
      position: absolute;
      right: 0;
    }
  }
  .file:hover {
    background-color: #f5f7fa;
    .close {
      display: inline-block;
      cursor: pointer;
    }
  }
}
// .row{
//     display: flex;
//     flex-wrap: wrap;
//     .file{
//         height: 30px;
//         display: flex;
//         align-items: center;
//         color: #3291F8;
//         cursor: pointer;
//         position: relative;
//         margin-left: 20px;
//         i{
//             position: absolute;
//             width: 14px;
//             height: 14px;
//             right: 0;
//             top: 0;
//             border: 1px solid #ccc;
//             border-radius: 50%;
//             color: #ccc;
//             font-size: 12px;
//             box-sizing: border-box;
//             transform: translate(50%,0);
//             display: none;

//         }
//     }
//     .file:hover{
//         // background-color: #f5f7fa;
//         i{
//             cursor: pointer;
//             display: inline-block;
//         }
//     }
// }
</style>
