<template>
    <div class="file-viewer">
        <iframe v-if="filePath" :key="previewUrl" :src="previewUrl" frameborder="0"></iframe>
    </div>
</template>

<script>
import { Base64 } from 'js-base64';

export default {
    props: {
        filePath: {
            type: String,
            default: ''
        },
    },
    computed: {
        fileViewerUrl(){
            return this.$store.getters.configMap['fileViewer'];
        },
        previewUrl(){
            return `${this.fileViewerUrl}/onlinePreview?url=${encodeURIComponent(Base64.encode(this.filePath))}&officePreviewType=pdf
            ${this.enabledWatermark && `&watermarkTxt=${this.markText}`}`
        },
        userInfo(){
            return this.$store.getters.userInfo;
        },
        markText(){
            return `${Date.dateFormat(new Date(),'YYYY-mm-dd HH:MM')} ${this.userInfo.realName} ${this.userInfo.court.name}`;
        },
        enabledWatermark(){
            return this.$store.getters.configMap['hrmsConfig'].watermark.enabled;
        }
    },
    data(){
        return {
            // 时间+姓名+法院
        }
    },
    methods: {
        
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
.file-viewer{
    width: 100%;
    height: 100%;
    iframe{
        width: 100%;
        height: 100%;
    }
}
</style>