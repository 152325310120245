<template>
    <div class="table-form" :class="{'detail': isDetail}">
        <div class="title">
            {{title}}
            <slot name="btn"></slot>
        </div>
        <table border="1">
            <tr v-for="(row,rowIndex) in template" :key="rowIndex">
                <td v-for="(col,colIndex) in row" :key="colIndex" 
                    :style="col.style"
                    :class="{'td-label': col.label,'disabled': col.disabled}" 
                    :rowspan="col.rowspan" :colspan="col.colspan" :width="col.width">
                    <div v-if="col.label" :class="{'require': col.require}">{{col.label}}</div>
                    <div class="value" v-else-if="col.value">
                        <slot v-if="col.slot" :name="col.slot" :form="form"></slot>
                        <div v-else-if="col.type === 'select'">
                            <el-select v-model="form[col.value]" :disabled="disabled(col)">
                                <el-option v-for="item in col.optionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </div>
                        <div v-else>
                            <el-input v-model="form[col.value]" :disabled="disabled(col)"></el-input>
                        </div>
                    </div>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        template: {         //  一个二维数组
            type: Array,
            default(){
                return [];
            }
        },
        form: {             //  表单
            type: Object,
            default(){
                return null;
            }
        },
        isDetail: {
            type: Boolean,
            default: true
        },
        disabledKeys: {
            type: Array,
            default(){
                return [];
            }
        }, 
		title: {
			type: String,
            default: '基本信息'
		}
    },
    methods: {
        disabled(col){      //  是否禁用
            return col.disabled || this.disabledKeys.includes(col.value) || this.isDetail;
        }
    },
}
</script>

<style lang="less">
.value{
    > div{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }
}
.td-label{
    background-color: #F0F2F5;
}
</style>