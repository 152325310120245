import router from '@/router';

const state = {
    contrastUserList: [],

    list1: [],
    list2: [],
    list3: [],
	notes: {},
	menuValue: null
}

const mutations = {
    addUser(state,data){
        state.contrastUserList.push(data);
    },
    delUser(state,index){
        state.contrastUserList.splice(index,1);
    },
    setUser(state,{userInfo,index}){
        // console.log(userInfo,index);
        state.contrastUserList.splice(index,1,userInfo);
        // console.log(state.contrastUserList);
    },
    exchangeUser(state,index){
        const tempUser = state.contrastUserList[index];
        // console.log(tempUser);
        state.contrastUserList.splice(index,1);
        state.contrastUserList.splice(index + 1,0,tempUser);
    },
    SET_LIST(state){        //  初始化当前已加入的对比数据
        const path = router.currentRoute.path;
        // console.log(currentRoute.path);
        if(path.includes('/cadre')){
            state.contrastUserList = state.list2;
        }else if(path.includes('/dean')){
            state.contrastUserList = state.list3;
        }else{
            state.contrastUserList = state.list1;
        }
    },
	SET_LOG(state, { path, note}) {
		state.notes.path = path
		state.notes.note = encodeURIComponent(note);
	},
	SET_MENUVLAUE(state, menuValue) {
		state.menuValue = menuValue
	}
}

const actions = {
    addContrastUser({ state , commit },data){
        return new Promise((resolve,reject) => {
            if(state.contrastUserList.length < 4){
                const has = state.contrastUserList.find(item => item.id === data.id);
                if(has){
                    reject('该干部已经加入对比，无需重复加入');
                }else{
                    commit('addUser',data);
                    resolve();
                }
    
            }else{
                reject('干部对比人数已满，无法继续加入');
            }

        })
    },
}

// actions.getDictMap({state});

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
