<template>
	<div class="row">
		<el-button v-if="!isDetail" type="primary" size="mini" @click="upload"
			v-permission="['/hrms/file/upload/attachment']">点击上传</el-button>
		<div class="files">
			<div class="file" v-for="(file,fileIndex) in fileSet" :key="fileIndex" @click="openFile(file.filePath)"
				:title="file.fileName">
				<span class="idx"> {{fileIndex+1}}、</span>
				<span class="left">
					<i class="el-icon-document"></i>
					<span class="name">{{file.fileName}}</span>
				</span>
				<span class="icons" v-if="!isDetail">
					<i @click.stop @mouseenter="draggable = true" @mouseleave="draggable = false"  class="drag" :style="{backgroundImage: `url(${dragImg})`}"></i>
					<i class="close iconfont icon-shanchu" @click.stop="delFile(file)"></i>
				</span>
			</div>
		</div>
		<input ref="inputFile" type="file" v-show="false" @change="change" multiple>
	</div>
</template>

<script>
import { uploadFile } from "@/api/fileAPI.js";
import { Base64 } from "js-base64";

export default {
  props: {
    fileList: {
      type: Array,
      default: () => [],
    },
    isDetail: {
      type: Boolean,
      required: true,
    },
    header: {
      type: Object,
      required: true,
    },
    files: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      fileSet: this.files,
      dragDirName: "",
      dropDirName: "",
	  dragImg: require('@/assets/drag.png'),
	  draggable: false,
	  container: null
    };
  },
  computed: {
    dirName() {
      return this.header.label;
    },
  },
  watch: {
    "fileList.length": {
      handler() {
        this.startDragDrop();
      },
    },
	files(newVal, oldVal) {
		if(newVal!==oldVal) {
			this.fileSet = this.files
		}
	},
	draggable(val) {
		this.container.childNodes.forEach(childNode => {
			if(val) {
				childNode.setAttribute("draggable", true);
			} else {
				childNode.setAttribute("draggable", false);
			}
		})
	}
  },
  methods: {
    upload() {
      this.$refs.inputFile.click();
    },
    openFile(filePath) {
      const fileViewer = this.$store.getters.configMap["fileViewer"];
      const url = encodeURIComponent(Base64.encode(filePath));
      const res = `${fileViewer}/onlinePreview?url=${url}&officePreviewType=pdf`;
      window.open(res, "_blank");
    },
    delFile(file) {
      const index = this.fileList.indexOf(file);
      this.fileList.splice(index, 1);
    },
    async change() {
      const files = this.$refs.inputFile.files;
      const note = `上传${this.dirName}文件`;
      const path = "/hrms/file/upload/attachment";
      this.$store.commit("status/SET_LOG", { path, note });
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const res = await uploadFile({ file });
        this.fileList.push({
          ...res,
          dirName: this.dirName,
        });
      }
      this.$refs.inputFile.value = "";
    },
    setDragDropEvent() {
      this.container = this.$el.querySelector(".files");
      // console.log(container.childNodes);
      let dragStartIndex = -1;
      let dropIndex = -1;
      this.container.childNodes.forEach((childNode, index) => {
        // childNode.setAttribute("draggable", true);
        childNode.ondragstart = () => {
          console.log("ondragstart");
          dragStartIndex = index;
          this.dragDirName = this.dirName;
        };
        childNode.ondragover = (event) => {
          event.preventDefault();
        };
        childNode.ondragenter = (event) => {
          event.preventDefault();
          dropIndex = index;
        };
        childNode.ondrop = () => {
          console.log("ondrop");
          this.dropDirName = this.dirName;
          console.log(this.dragDirName, this.dropDirName);
		  if(!this.draggable) return
          if (this.dropDirName && this.dragDirName !== this.dropDirName) return; // 防止不同目录拖拽
          if (dragStartIndex === dropIndex) return;
          const dragFile = this.fileSet.splice(dragStartIndex, 1)[0];
          this.fileSet.splice(dropIndex, 0, dragFile);
          this.fileSet.forEach((file, i) => {
            file.sn = i + 1;
          });
        };
      });
    },
    startDragDrop() {
      if (!this.isDetail) {
        this.$nextTick(() => {
          this.setDragDropEvent();
        });
      }
    },
  },
  mounted() {
    this.startDragDrop();
  }
};
</script>

<style lang="less" scoped>
.row {
  display: block;
  width: 420px;
  .file {
    height: 26px;
    line-height: 26px;
    color: #606266;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .idx {
      width: 30px;
    }

    .left {
      padding-right: 12px;
      width: calc(100% - 30px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .name:hover {
        color: #409eff;
        cursor: pointer;
      }
      i {
        margin-right: 5px;
      }
    }

	.icons {
		position: relative;
		height: 100%;
		.drag {
			font-size: 0;
			display: inline-block;
			background-size: 14px;
			background-repeat: no-repeat;
			background-position: center;
			height: 26px;
			width: 26px;
			position: absolute;
      		right: 30px;
		}
		.close {
      		display: none;
      		position: absolute;
      		right: 10px;
    	}
	}

  }
  .file:hover {
    background-color: #f5f7fa;
    .close {
      display: inline-block;
      cursor: pointer;
    }
  }
}
</style>