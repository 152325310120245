const getters = {
    // permissions: state => state.common.permissions,
    dictMap: state => state.common.dictMap,
    configMap: state => state.common.configMap,
    // configMap: state => state.common.configMap,
    token: state => state.common.token,
    userInfo: state => state.common.userInfo,
    permissions: state => state.common.permissions,
    contrastUserList: state => state.status.contrastUserList,
}

export default getters
