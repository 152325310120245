<template>
    <div>
         <el-select size="small" :value="selectIds" @input="input" placeholder="请选择" :multiple="true" :disabled="disabled">
            <el-option-group v-for="group in options" :key="group.label" :label="group.label">
                <el-option
                    v-for="item in group.options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-option-group>
        </el-select>
    </div>
</template>

<script>
import { batchDel } from '@/utils/handle.js';

export default {
    props: {
        options: {
            type: Array,
            default(){
                return [];
            }
        },
        value: {
            type: String,
            default: ''
        },
        disabled: Boolean
    },
    watch: {
        value: {
            handler(val){
                // console.log(val);
                if(this.value){
                    this.selectIds = val.split(',');
                }else{
                    this.selectIds = [];
                }
                
            },
            immediate: true,
        }
    },
    data(){
        return {
            selectIds: [],
        }
    },
    methods: {
        input(list){

            //  每个分类层级只能选择一个
            // console.log(this.options);
            // console.log(list,this.value.split(','));
            const nList = this.value && this.value.split(',') || [];
            let target;
            if(list.length > nList.length){         //  取消选择不用管
                target = list[list.length - 1];     //  新选择的项(取消选择不用管)
            }
            if(target){     //  选择新的一项
                this.options.forEach(option => {
                    if(option.options.find(childOption => childOption.value === target)){
                        batchDel(list,item => option.options.find(option => option.value === item));
                        // console.log('target',target);
                        list.push(target);
                    }
                })
            }
            this.$emit('input',list.join(','));
        }
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>

</style>