<template>
    <div id="app">
        <router-view />
        <el-dialog :title="title" :visible.sync="open" width="80%" :close-on-click-modal="false" @close="cancel" v-if="open">
            <div class="detail-container" v-if="open">
                <Detail v-if="showDetail" :detailInfo="formData" :isDetail="isDetail" key="APP"
                :disabledKeys="disabledKeys"
                ></Detail>
                <!-- ADJUST_POST(调职) -->
                <TransferForm ref="transferForm" v-if="flowType === 'ADJUST_POST'" :form="formData"></TransferForm>
                <!-- LEAVE(离退) -->
                <LeaveForm ref="leaveForm" v-if="flowType === 'LEAVE'" :form="formData"></LeaveForm>
            </div>
            
            <div slot="footer" v-if="!isDetail">
                <el-button size="small" @click="cancel">关闭</el-button>
                <el-button size="small" type="success" @click="save">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Detail from '@/components/detail';
import TransferForm from '@/components/form/TransferForm.vue';
import LeaveForm from '@/components/form/LeaveForm.vue';

export default {
    components: {
        Detail,
        TransferForm,
        LeaveForm
    },
    data(){
        return {
            open: false,
            formData: {},   //  表单信息
            updateResolve: null,    //  修改完回调
            updateReject: null,
            flowType: '',
            isDetail: false,
            title: '',
            callback: null,     //  回调函数
        }
    },
    provide(){
        return {
            updateForm: this.updateForm,
            detailForm: this.detailForm,
        }
    },
    computed: {
        disabledKeys(){
            if(!this.isDetail){
				if(this.flowType === 'EDIT') {
					return ['idCard']
				} else {
					return ['idCard','orgId','currentPositionIds'];
				}
            }else{
                return [];
            }
        },
		showDetail() {
			const types =  ['REGISTER', 'CHANGE', 'EDIT'] 
			return types.includes(this.flowType)
		}
    },
    methods: {
        updateForm(formData,flowType,title,callback){
            this.title = title;
            this.isDetail = false;
            this.flowType = flowType;
            this.formData = JSON.parse(JSON.stringify(formData));
            // if(flowType === 'REGISTER' || flowType === 'CHANGE'){
            if(this.showDetail){
                if(!this.formData.fileInfoList){
                    this.formData.fileInfoList = [];
                }
                if(!this.formData.educationInfoList){
                    this.formData.educationInfoList = [];
                }
            }
            
            this.open = true;
            this.callback = callback;
        },
        detailForm(formData){
            this.title = '查看详情';
            this.formData = JSON.parse(JSON.stringify(formData));
            this.flowType = 'REGISTER';
            this.isDetail = true;
            this.open = true;
        },
        async save(){
            if(this.flowType === 'ADJUST_POST'){
                await this.$refs.transferForm.submit();
            }
            if(this.flowType === 'LEAVE'){
                await this.$refs.leaveForm.submit();
            }
            this.callback(this.formData,() => {
                this.open = false;
            });
        },
        cancel(){
            this.open = false;
        }
    }
}
</script>

<style lang="less" scoped>
.detail-container{
    height: 560px;
    overflow: auto;
}
</style>
