<template>
    <ul class="menu-list" >
        <li class="menu-item" :class="{'active': value === item.value}" v-for="(item,index) in menuList" :key="index" @click="menuClick(item)" ref="menu">
            <p >{{item.label}}</p>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        menuList: {
            type: Array,
            default(){
                return [];
            }
        },
        value: String
    },
    data(){
        return {
            
        }
    },
    methods: {
        menuClick(item){
            // console.log(item.value);
            this.$emit('input',item.value);
            this.$emit('menuClick',item);
        }
    },

}
</script>

<style lang="less" scoped>
.menu-list{
    display: flex;
    height: 100%;
    .menu-item{
        height: 100%;
        margin: 0 5px;
        padding: 0 15px;
        cursor: pointer;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        border-bottom: 2px solid transparent;
        
    }
    .menu-item.active{
        color: #008B61;
        border-color: #008B61;
    }
}

</style>