import { asyncRoutes , resetRouter , speRoutes } from '@/router';
import store from '@/store';
import { traverse , copyTree } from '@/utils/handle.js';


export function generateRoutes(routes){
    const permissions = store.getters.permissions;
    // console.log(permissions);
    const regList = permissions.map(permit => {         //  正则
        if (permit.indexOf("**") != -1) {
            permit = permit.replace("**", ".*");
        } else {
            if (permit.indexOf("*") != -1) {
                permit = permit.replace("*", "[a-zA-Z0-9_\\-]*");
            }
        }
        return new RegExp(`^${permit}$`);
    })
    // console.log(regList);
    const newRoutes = [];
    function recursion(arr,nArr){
        arr.forEach(route => {
            const newRoute = {
                ...route,
                component: route.component,     //  重新赋值，不然渲染不了
                children: []
            };       
			const urls = route.meta && route.meta.url && route.meta.url.replace(/\s/g, '').split(',')
            // console.log( route,'route');
            if(urls){
                let flag = regList.find(reg => {
                    return urls.find(url => reg.test(url));     //  只要有一个权限，页面就会渲染
                })
                if(flag){           
                    nArr.push(newRoute);
                }
            }else{
                nArr.push(newRoute);
            }
            if(route.children && route.children.length){
                recursion(route.children,newRoute.children);
            }
        })
    }

    recursion(addTwoLayoutRoutes(),newRoutes);


    traverse(newRoutes,route => {
        if(route.children && route.children.length){        
            const target = route.children.find(item => !item.hasOwnProperty('link'));     //  非外链的route
            // console.log('route-redirect',route,target);
            if(target){         //      顺延不停赋值redirect
                route.redirect = target.path;
            }
        }
    })
    
    // console.log('newRoutes',newRoutes);

    resetRouter(newRoutes);
}

function addTwoLayoutRoutes(){          //  三倍路由
	const newRoutes = copyTree(asyncRoutes,route => {
		return {
			...route,
			component: route.component,     //  重新赋值，不然渲染不了
			path: route.path,
			name: route.name,
		}
	})
    // console.log(newRoutes);
	const cadreRoutes = copyTree(asyncRoutes,route => {
        if(route.name === 'layoutSystem'){
            return null;
        }
        const path = route.path + '/cadre';
        if(speRoutes.find(item => item.path === path)){         //  特殊route，直接替换
            return speRoutes.find(item => item.path === path);
        }
		return {
			...route,
			component: route.component,     //  重新赋值，不然渲染不了
			path: route.path + '/cadre',
			name: route.name + 'Cadre',
            meta: {
                ...route.meta,
                url: route.meta && route.meta.url && route.meta.url.replace(/\/hrms/g,'/hrms/cadre')
            }
		}
	})
    // console.log('cadreRoutes',cadreRoutes);
	const deanRoutes = copyTree(asyncRoutes,route => {
        // console.log(route);
        if(route.name === 'layoutSystem'){
            return null;
        }
        const path = route.path + '/dean';
        if(speRoutes.find(item => item.path === path)){
            return speRoutes.find(item => item.path === path);
        }
		return {
			...route,
			component: route.component,     //  重新赋值，不然渲染不了
			path: route.path + '/dean',
			name: route.name + 'Dean',
            meta: {
                ...route.meta,
                url: route.meta && route.meta.url && route.meta.url.replace(/\/hrms/g,'/hrms/dean')
            }
		}
	})
	// console.log(cadreRoutes);
	return [
		...newRoutes,
		...cadreRoutes,
		...deanRoutes
	]
}