<template>
    <div class="search">
        <div class="search-item" v-for="item in paramsHeaders" :key="item.value">
            <div class="label">{{item.label}}</div>
            <div class="content">
                <template>
                    <slot v-if="item.slot" :name="item.slot"></slot>
                    <div v-else-if="item.type === 'select'">
                        <el-select v-model="params[item.value]" size="small" clearable :style="{ width: `${item.width}px`}">
                            <el-option v-for="option in item.typeList" :key="option.value" :label="option.label" :value="option.value"></el-option>
                        </el-select>
                    </div>
                    <el-input v-else v-model="params[item.value]" size="small" clearable :placeholder="'请输入'+item.label" :style="{ width: `${item.width}px`}"></el-input>
                </template>
            </div>
        </div>
        <div class="operate" v-if="hasSearch">
            <button class="btn submit-btn ml10" @click="dataInit">搜索</button>
            <button class="btn plain-btn ml10" @click="restore">重置</button>
        </div>
        <slot name="operate">
            
        </slot>
    </div>
</template>

<script>
export default {
    props: {
        paramsHeaders: {
            type: Array,
            default(){
                return [];
            }
        },
        params: {
            type: Object,
            default(){
                return {};
            }
        },
        getTableData: Function,
        ignoreKeys: {
            type: String,
            default: ''
        },
        hasSearch: {
            type: Boolean,
            default: true
        }
    },

    data(){
        return {
            
        }
    },
    watch: {
        'params.current': {
            handler(val){
                this.getTableData();
            }
        },
        'params.size': {
            handler(val){
                this.getTableData();
            }
        }
    },
    methods: {
        dataInit(){
            if(this.getTableData instanceof Function){
                this.getTableData();
            }
        },
        restore(){
            Object.keys(this.params).forEach(key => {
                if(this.ignoreKeys.includes(key)){
                    return;
                }
                switch(key){
                    case 'current':
                        this.params[key] = 1;
                    break;
                    case 'size':
                        this.params[key] = 30;
                    break;
                    default:
                        this.params[key] = '';
                    break;
                }
            })

			location.search = ''

            if(this.getTableData instanceof Function){
                this.getTableData();
            }
        },
    },
    mounted(){},
}
</script>

<style lang="less" scoped>
.search{
    display: flex;
	flex-wrap: wrap;
    font-size: 14px;

    .search-item{
        margin-right: 20px;
		margin-bottom: 20px;
        display: flex;
        align-items: center;
		justify-content: center;
        .label{
            margin-right: 10px;
            color: #101010;
        }
        .content{

        }
    }
    /deep/ .el-date-editor{
        width: 350px;
    }
    /deep/ .el-select{
        width: 150px;
    }
}
</style>