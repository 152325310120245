import Vue from 'vue';
import { EnumList } from '@/enum.js';

//  教育信息(学历学位信息)
const education = [
	{
		label: '开始时间',
		value: 'startTime',
		width: '12%',
		slot: 'startTime'
	},
	{
		label: '结束时间',
		value: 'endTime',
		width: '12%',
		slot: 'endTime'
	},
	{
		label: '学历',
		value: 'qualification',
		width: '12%',
		type: 'select',
		require: true,
		optionList: EnumList.educationList
	},
	{
		label: '学位',
		value: 'degree',
		width: '12%',
		type: 'select',
		optionList: EnumList.degreeList
	},
	{
		label: '学制',
		value: 'educationalSystem',
		width: '12%',
		type: 'select',
		require: true,
		optionList: EnumList.educationalSystemList
	},
	{
		label: '专业分类',
		value: 'major',
		width: '12%',
		type: 'select',
		require: true,
		optionList: EnumList.majorList

	},
	{
		label: '毕业院校系及专业',
		value: 'institutionsMajors'
	},
]

//  职务信息
const duties = [
	{
		label: '单位',
		value: 'orgId',
		slot: 'org',
		require: true
	},
	{
		// label: '现任职务',
		label: '职务',
		value: 'currentPositionIds',
		slot: 'currentPositionIds',
		require: true,
		type: 'select',
		typeList: [],
	},
]

//  其他库的职务信息
const otherDuties = [
	{
		label: '现任法院/部门',
		value: 'orgId',
		slot: 'org',
		require: true
	},
	{
		label: '现任职务',
		value: 'currentPositionIds',
		slot: 'currentPositionIds',
		require: true,
		type: 'select',
		typeList: [],

	},
	{
		label: '拟任职务',
		value: 'proposedPositionIds',
		slot: 'proposedPositionIds',
		type: 'select',
		typeList: [],
	},
	{
		label: '拟免职务',
		value: 'retirementPositionIds',
		slot: 'retirementPositionIds',
		type: 'select',
		typeList: [],
	},
]

//  简历
const resume = [
	{
		label: '开始时间',
		value: 'startTime',
		slot: 'startTime',
		width: '15%'
	},
	{
		label: '结束时间',
		value: 'endTime',
		slot: 'endTime',
		width: '15%'
	},
	{
		label: '描述',
		value: 'description',
	},
]
//  考核情况
const kaohe = [
	{
		label: '奖惩信息',
		value: 'rewardPunishmentState',
	},
	{
		label: '年度考核结果',
		value: 'checkResult',
	},
	{
		label: '群众满意度排名',
		value: 'appointRemoveReason',
	},
]

const kaohe1 = [
	{
		label: '奖惩情况',
		value: 'rewardPunishmentState',
	},
]

const kaohe2 = [
	{
		label: '年度考核结果',
		value: 'checkResult',
	},
]

//  家庭主要成员及重要社会关系
const family = [
	{
		label: '称谓',
		value: 'appellation',
		width: '11%'
	},
	{
		label: '姓名',
		value: 'name',
		width: '13%'
	},
	{
		label: '年龄',
		value: 'age',
		width: '11%',
		slot: 'age'
	},
	{
		label: '政治面貌',
		value: 'politicalAffiliation',
		width: '13%'
	},
	{
		label: '工作单位及职务',
		value: 'unitDuties',
	},
]
//  呈报信息
const report = [

]
//  2,3库呈报信息
const otherReport = [
	[
		{
			label: '呈报单位意见',
		},
		{
			value: 'reportUnit',
		},
		{
			label: '日期',
		},
		{
			value: 'repostTime',
			slot: 'repostTime'
		},
	],
	[
		{
			label: '审核单位意见',
		},
		{
			value: 'approvalAuthorityOpinion',
		},
		{
			label: '日期',
		},
		{
			value: 'approvalSignTime',
			slot: 'approvalSignTime'
		},
	],
]

const deanReport = [
	{
		label: '呈报单位意见',
		value: 'reportUnit',
		link: 'reportUnit',
	},
	{
		label: '日期',
		value: 'repostTime',
		link: 'reportUnit',
	},
	{
		label: '审核单位意见',
		value: 'approvalAuthorityOpinion',
		link: 'approvalAuthorityOpinion',
	},
	{
		label: '日期',
		value: 'approvalSignTime',
		link: 'approvalAuthorityOpinion',
	},
	// {
	//     label: '行政机关任免意见',
	//     value: 'administrationAppointRemoveOpinion',
	//     link: 'administrationAppointRemoveOpinion',
	// },
	// {
	//     label: '日期',
	//     value: 'administrationSignTime',
	//     link: 'administrationAppointRemoveOpinion',
	// },
	// {
	//     label: '填表人',
	//     value: 'preparer',
	//     link: 'preparer',
	// },
	// {
	//     label: '日期',
	//     value: 'preparerSignTime',
	//     link: 'preparer',
	// },
]

//  附件材料
const annex = [
	{
		label: '干部任免审批表',
		value: 'preparerSignTime',
		width: '29%'
	},
	{
		label: '政治素质双向测评情况',
		value: 'preparerSignTime',
		width: '29%'
	},
	{
		label: '年度民主生活会对照检查材料',
		value: 'preparerSignTime',
		width: '29%'
	},
	{
		label: '年度考核表',
		value: 'preparerSignTime',
		width: '29%'
	},
	{
		label: '年度述职述廉述学述法报告',
		value: 'preparerSignTime',
		width: '29%'
	},
	{
		label: '发表文章及参加学习培训情况',
		value: 'preparerSignTime',
		width: '29%'
	},
	{
		label: '违纪违法情况材料',
		value: 'preparerSignTime',
		width: '29%'
	},
	{
		label: '证明干部个人及单位审判质效的材料',
		value: 'preparerSignTime',
		width: '29%'
	},
	{
		label: '审务督察和司法巡查情况材料',
		value: 'preparerSignTime',
		width: '29%'
	},
	{
		label: '其他需要存档的材料',
		value: 'preparerSignTime',
		width: '29%'
	},
]

//  审批流程
const examine = [
	{
		label: '操作时间',
		value: 'startTime'
	},
	{
		label: '操作人员',
		value: 'userName'
	},
	{
		label: '审批节点',
		value: 'examineNode'
	},
	{
		label: '执行结果',
		value: 'result'
	},
	{
		label: '审批意见',
		value: 'opinion'
	},
]

//  民族集合
const nation = [
	{
		"label": "汉族",
		"value": "汉族"
	},
	{
		"label": "回族",
		"value": "回族"
	},
	{
		"label": "满族",
		"value": "满族"
	},
	{
		"label": "藏族",
		"value": "藏族"
	},
	{
		"label": "阿昌族",
		"value": "阿昌族"
	},
	{
		"label": "白族",
		"value": "白族"
	},
	{
		"label": "保安族",
		"value": "保安族"
	},
	{
		"label": "布朗族",
		"value": "布朗族"
	},
	{
		"label": "布依族",
		"value": "布依族"
	},
	{
		"label": "朝鲜族",
		"value": "朝鲜族"
	},
	{
		"label": "达斡尔族",
		"value": "达斡尔族"
	},
	{
		"label": "傣族",
		"value": "傣族"
	},
	{
		"label": "德昂族",
		"value": "德昂族"
	},
	{
		"label": "侗族",
		"value": "侗族"
	},
	{
		"label": "东乡族",
		"value": "东乡族"
	},
	{
		"label": "独龙族",
		"value": "独龙族"
	},
	{
		"label": "鄂伦春族",
		"value": "鄂伦春族"
	},
	{
		"label": "俄罗斯族",
		"value": "俄罗斯族"
	},
	{
		"label": "鄂温克族",
		"value": "鄂温克族"
	},
	{
		"label": "高山族",
		"value": "高山族"
	},
	{
		"label": "仡佬族",
		"value": "仡佬族"
	},
	{
		"label": "哈尼族",
		"value": "哈尼族"
	},
	{
		"label": "哈萨克族",
		"value": "哈萨克族"
	},
	{
		"label": "赫哲族",
		"value": "赫哲族"
	},
	{
		"label": "基诺族",
		"value": "基诺族"
	},
	{
		"label": "京族",
		"value": "京族"
	},
	{
		"label": "景颇族",
		"value": "景颇族"
	},
	{
		"label": "柯尔克孜族",
		"value": "柯尔克孜族"
	},
	{
		"label": "拉祜族",
		"value": "拉祜族"
	},
	{
		"label": "黎族",
		"value": "黎族"
	},
	{
		"label": "傈僳族",
		"value": "傈僳族"
	},
	{
		"label": "珞巴族",
		"value": "珞巴族"
	},
	{
		"label": "毛南族",
		"value": "毛南族"
	},
	{
		"label": "门巴族",
		"value": "门巴族"
	},
	{
		"label": "蒙古族",
		"value": "蒙古族"
	},
	{
		"label": "苗族",
		"value": "苗族"
	},
	{
		"label": "仫佬族",
		"value": "仫佬族"
	},
	{
		"label": "纳西族",
		"value": "纳西族"
	},
	{
		"label": "怒族",
		"value": "怒族"
	},
	{
		"label": "普米族",
		"value": "普米族"
	},
	{
		"label": "羌族",
		"value": "羌族"
	},
	{
		"label": "撒拉族",
		"value": "撒拉族"
	},
	{
		"label": "畲族",
		"value": "畲族"
	},
	{
		"label": "水族",
		"value": "水族"
	},
	{
		"label": "塔吉克族",
		"value": "塔吉克族"
	},
	{
		"label": "塔塔尔族",
		"value": "塔塔尔族"
	},
	{
		"label": "土族",
		"value": "土族"
	},
	{
		"label": "佤族",
		"value": "佤族"
	},
	{
		"label": "锡伯族",
		"value": "锡伯族"
	},
	{
		"label": "乌兹别克族",
		"value": "乌兹别克族"
	},
	{
		"label": "瑶族",
		"value": "瑶族"
	},
	{
		"label": "彝族",
		"value": "彝族"
	},
	{
		"label": "裕固族",
		"value": "裕固族"
	},
	{
		"label": "维吾尔族",
		"value": "维吾尔族"
	},
	{
		"label": "壮族",
		"value": "壮族"
	},
	{
		"label": "土家族",
		"value": "土家族"
	}
]
//  基础信息模板
const baseInfoTemplate = [
	[
		{
			label: '姓名',
			require: true,
			width: '15%'
		},
		{
			value: 'name',
			width: '35%'
		},
		{
			label: '性别',
			require: true,
			width: '15%'
		},
		{
			value: 'sex',
			slot: 'sex'
		},
		{
			value: 'avatar',
			slot: 'avatar',
			rowspan: 3,
			style: {
				width: '105px',
				height: '145px',
				padding: 0
			}
		}
	],
	[
		{
			label: '出生年月',
			require: true,
		},
		{
			value: 'birthday',
			slot: 'birthday'
		},
		{
			label: '年龄',
		},
		{
			value: 'age',
			disabled: true
		},

	],
	[
		{
			label: '身份证号',
			require: true,
		},
		{
			value: 'idCard',
			slot: 'idCard'
		},
		{
			label: '民族',
			require: true,
		},
		{
			value: 'nation',
			type: 'select',
			optionList: nation
		},
	],
	[
		{
			label: '籍贯',
		},
		{
			value: 'nativePlace',
		},
		{
			label: '出生地',
		},
		{
			value: 'birthPlace',
			colspan: 2
		},
	],
	[
		{
			label: '政治面貌',
			require: true
		},
		{
			value: 'politicalAppearance',
			type: 'select',
			optionList: EnumList.ZZMMList
		},
		{
			label: '入党时间',
		},
		{
			value: 'joinPartyTime',
			slot: 'joinPartyTime',
			colspan: 2
		},
	],
	[
		{
			label: '参加工作时间',
			require: true
		},
		{
			value: 'joinWorkTime',
			slot: 'joinWorkTime',
		},
		{
			label: '工作年限',
		},
		{
			value: 'workYear',
			colspan: 2,
			disabled: true
		},
	],
	[
		{
			label: '健康状况',
		},
		{
			value: 'healthStatus'
		},
		{
			label: '专业方向',
		},
		{
			value: 'majorStrength',
			colspan: 2
		},
	],
]

//  后2个库的基础信息模板
const otherBaseInfoTemplate = [
	[
		{
			label: '姓名',
			require: true,
			width: '15%'
		},
		{
			value: 'name',
			width: '35%'
		},
		{
			label: '性别',
			require: true,
			width: '15%'
		},
		{
			value: 'sex',
			slot: 'sex'
		},
		{
			value: 'avatar',
			slot: 'avatar',
			rowspan: 3,
			style: {
				width: '105px',
				height: '145px',
				padding: 0
			}
		}
	],
	[
		{
			label: '出生年月',
			require: true,
		},
		{
			value: 'birthday',
			slot: 'birthday'
		},
		{
			label: '年龄',
		},
		{
			value: 'age',
			disabled: true
		},

	],
	[
		{
			label: '身份证号',
			require: true,
		},
		{
			value: 'idCard',
			slot: 'idCard'
		},
		{
			label: '民族',
			require: true
		},
		{
			value: 'nation',
			type: 'select',
			optionList: nation
		},
	],
	[
		{
			label: '籍贯',
		},
		{
			value: 'nativePlace',
		},
		{
			label: '出生地',
		},
		{
			value: 'birthPlace',
			colspan: 2
		},
	],
	[
		{
			label: '政治面貌',
			require: true
		},
		{
			value: 'politicalAppearance',
			type: 'select',
			optionList: EnumList.ZZMMList
		},
		{
			label: '入党时间',
		},
		{
			value: 'joinPartyTime',
			slot: 'joinPartyTime',
			colspan: 2
		},
	],
	[
		{
			label: '参加工作时间',
			require: true
		},
		{
			value: 'joinWorkTime',
			slot: 'joinWorkTime'
		},
		{
			label: '工作年限',
		},
		{
			value: 'workYear',
			colspan: 2,
			disabled: true
		},
	],
	[
		{
			label: '健康状况',
		},
		{
			value: 'healthStatus'
		},
		{
			label: '专业技能职务',
		},
		{
			value: 'specializedSkills',
			colspan: 2
		},
	],
	[
		{
			label: '熟悉专业/有何专长',
		},
		{
			value: 'majorStrength'
		},
		{
			label: ' ',
		},
		{
			value: '',
			colspan: 2
		},
	],
]

export {
	education,
	duties,
	otherDuties,
	resume,
	kaohe,
	kaohe1,
	kaohe2,
	family,
	report,
	otherReport,
	annex,
	examine,
	nation,
	baseInfoTemplate,
	otherBaseInfoTemplate
}