var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.detailInfo)?_c('div',{class:{'isDetail': _vm.isDetail},attrs:{"id":"print"}},[_c('OtherBaseInfo',{attrs:{"form":_vm.detailInfo,"isDetail":_vm.isDetail,"disabledKeys":_vm.disabledKeys}}),_c('ColumnTable',{staticClass:"mt15",attrs:{"title":_vm.educationTitle,"headers":_vm.educationHeaders,"tableData":_vm.educationInfoList,"isDetail":_vm.isDetail,"hasOperate":!_vm.isDetail},scopedSlots:_vm._u([{key:"startTime",fn:function(ref){
var form = ref.form;
return [_c('el-date-picker',{attrs:{"disabled":_vm.isDetail,"type":"month","value-format":"yyyy-MM","pickerOptions":{
                        disabledDate: function disabledDate(time) {
                            return _vm.startTimeDisabledDate(time, form.endTime)
                        }
                    }},model:{value:(form.startTime),callback:function ($$v) {_vm.$set(form, "startTime", $$v)},expression:"form.startTime"}})]}},{key:"endTime",fn:function(ref){
                    var form = ref.form;
return [_c('el-date-picker',{attrs:{"disabled":_vm.isDetail,"type":"month","value-format":"yyyy-MM","format":"yyyy.MM","pickerOptions":{
                        disabledDate: function disabledDate(time) {
                            return _vm.endTimeDisabledDate(time, form.startTime)
                        }
                    }},model:{value:(form.endTime),callback:function ($$v) {_vm.$set(form, "endTime", $$v)},expression:"form.endTime"}})]}}],null,false,1363679186)}),_c('RowTable',{staticClass:"mt15",attrs:{"title":_vm.jobTitle,"headers":_vm.dutiesHeaders,"form":_vm.positionInfo,"isDetail":_vm.isDetail,"disabledKeys":_vm.disabledKeys}},[_c('template',{slot:"org"},[_c('MyCascader',{attrs:{"disabled":_vm.isDetail || _vm.disabledKeys.includes('orgId'),"options":_vm.filteredOrgList,"props":{
                    checkStrictly: true,
                    label: 'name',
                    value: 'id'
                }},model:{value:(_vm.positionInfo.orgId),callback:function ($$v) {_vm.$set(_vm.positionInfo, "orgId", $$v)},expression:"positionInfo.orgId"}})],1),_c('template',{slot:"currentPositionIds"},[(_vm.isDetail || _vm.disabledKeys.includes('currentPositionIds'))?_c('div',[_vm._v(_vm._s(_vm.positionLabels[0]))]):_c('MySelectGroup',{attrs:{"options":_vm.dutiesHeaders[1].optionList,"disabled":_vm.isDetail || _vm.disabledKeys.includes('currentPositionIds')},model:{value:(_vm.positionInfo.currentPositionIds),callback:function ($$v) {_vm.$set(_vm.positionInfo, "currentPositionIds", $$v)},expression:"positionInfo.currentPositionIds"}})],1),(_vm.dutiesHeaders[2])?_c('template',{slot:"proposedPositionIds"},[(_vm.isDetail)?_c('div',[_vm._v(_vm._s(_vm.positionLabels[1]))]):_c('MySelectGroup',{attrs:{"options":_vm.dutiesHeaders[2].optionList,"disabled":_vm.isDetail},model:{value:(_vm.positionInfo.proposedPositionIds),callback:function ($$v) {_vm.$set(_vm.positionInfo, "proposedPositionIds", $$v)},expression:"positionInfo.proposedPositionIds"}})],1):_vm._e(),(_vm.dutiesHeaders[3])?_c('template',{slot:"retirementPositionIds"},[_c('MySelectGroup',{attrs:{"options":_vm.dutiesHeaders[3].optionList,"disabled":_vm.isDetail},model:{value:(_vm.positionInfo.retirementPositionIds),callback:function ($$v) {_vm.$set(_vm.positionInfo, "retirementPositionIds", $$v)},expression:"positionInfo.retirementPositionIds"}})],1):_vm._e()],2),_c('ColumnTable',{staticClass:"mt15",attrs:{"title":_vm.easyTitle,"headers":_vm.resumeHeaders,"tableData":_vm.biographicalNotesInfoList,"isDetail":_vm.isDetail,"hasOperate":!_vm.isDetail},scopedSlots:_vm._u([{key:"startTime",fn:function(ref){
                var form = ref.form;
return [_c('el-date-picker',{attrs:{"disabled":_vm.isDetail,"type":"month","value-format":"yyyy-MM","format":"yyyy.MM","pickerOptions":{
                        disabledDate: function disabledDate(time) {
                            return _vm.startTimeDisabledDate(time, form.endTime)
                        }
                    }},model:{value:(form.startTime),callback:function ($$v) {_vm.$set(form, "startTime", $$v)},expression:"form.startTime"}})]}},{key:"endTime",fn:function(ref){
                    var form = ref.form;
return [_c('el-date-picker',{attrs:{"disabled":_vm.isDetail,"type":"month","value-format":"yyyy-MM","format":"yyyy.MM","pickerOptions":{
                        disabledDate: function disabledDate(time) {
                            return _vm.endTimeDisabledDate(time, form.startTime)
                        }
                    }},model:{value:(form.endTime),callback:function ($$v) {_vm.$set(form, "endTime", $$v)},expression:"form.endTime"}})]}}],null,false,2285405513)}),(_vm.currentLib)?_c('RowTable',{staticClass:"mt15",attrs:{"title":"奖惩信息","headers":_vm.kaohe1,"form":_vm.assessmentSituationsInfo,"isDetail":_vm.isDetail}}):_vm._e(),(_vm.currentLib)?_c('RowTable',{staticClass:"mt15",attrs:{"title":"年度考核信息","headers":_vm.kaohe2,"form":_vm.assessmentSituationsInfo,"isDetail":_vm.isDetail}}):_vm._e(),(!_vm.currentLib)?_c('RowTable',{staticClass:"mt15",attrs:{"title":"考核情况","headers":_vm.kaoheHeaders,"form":_vm.assessmentSituationsInfo,"isDetail":_vm.isDetail}}):_vm._e(),_c('ColumnTable',{staticClass:"mt15",attrs:{"title":_vm.$replaceText('家庭主要成员及重要社会关系信息'),"headers":_vm.familyHeaders,"tableData":_vm.userRelationInfoList,"isDetail":_vm.isDetail,"hasOperate":!_vm.isDetail},scopedSlots:_vm._u([{key:"age",fn:function(ref){
                    var form = ref.form;
return [_c('el-input-number',{attrs:{"disabled":_vm.isDetail,"value":form.age || undefined,"clearable":""},on:{"input":function (val) { return form.age = val ? val : undefined; }}})]}}],null,false,1919985689)}),(_vm.currentLib)?_c('Report',{staticClass:"mt15",attrs:{"form":_vm.presentationInfo,"isDetail":_vm.isDetail}}):_vm._e(),_c('UploadSort',{staticClass:"mt15",attrs:{"fileList":_vm.fileInfoList,"isDetail":_vm.isDetail,"cadreFileList":_vm.cadreFileList}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }