<template>
    <div class="base-info">
        <TableForm :template="template" :form="form" :isDetail="isDetail">
            <template slot="sex">
				<div v-if="!isDetail">
                    <el-radio-group v-model="form.sex">
                        <el-radio label="MALE">男</el-radio>
                        <el-radio label="FEMALE">女</el-radio>
                    </el-radio-group>
                </div>
                <div v-else>
                    {{form.sex === 'MALE' ? '男' : form.sex === 'FEMALE' ? '女' : ''}}
                </div>
            </template>
            <template slot="avatar">
                <div class="user-image">
                    <input ref="inputFile" type="file" v-show="false" @change="inputChange">
                    <div v-if="!isDetail && !form.avatar" class="upload" @click="openPopup">
                        <i class="iconfont icon-tianjia"></i>
                        <span>上传头像</span>
                    </div>
                    <div v-else class="img-container">
                        <img v-if="form.avatar" :src="form.avatar" alt="">
                        <img v-else src="@/assets/user-default.png" alt="">
                        <i v-if="!isDetail" class="del iconfont icon-shanchu" @click="delFile"></i>
                    </div>
                </div>
            </template>
            <template slot="birthday">
                <el-date-picker v-model="form.birthday" type="month" :disabled="isDetail" @change="birthdayChange"
                        value-format="yyyy-MM" format="yyyy.MM" :picker-options="pickerOptions"></el-date-picker>
            </template>
            <template slot="idCard">
                <div class="idCard">
                    <el-input v-model="form.idCard" :disabled="disabledKeys.includes('idCard') || isDetail"
                        style="padding-right:24px;box-sizing:border-box;">
                    </el-input>
                    <button v-if="(form.idCard && !isDetail) && !disabledKeys.includes('idCard')" class="btn" @click="check">查重</button>
                </div>
            </template>
            <template slot="joinPartyTime">
                <el-date-picker v-model="form.joinPartyTime" type="month" :disabled="isDetail"
                        :picker-options="pickerOptions" value-format="yyyy-MM" format="yyyy.MM"></el-date-picker>
            </template>
            <template slot="joinWorkTime">
                <el-date-picker v-model="form.joinWorkTime" type="month" :disabled="isDetail"
                        @change="workTimeChange" value-format="yyyy-MM" format="yyyy.MM"
                        :picker-options="pickerOptions"></el-date-picker>
            </template>
        </TableForm>
		<AvatarPopup ref='popup' @upload="upload"></AvatarPopup>
    </div>
</template>	

<script>
import TableForm from '@/common/module/tableForm';
import AvatarPopup from './AvatarPopup';
import { uploadAvatar } from '@/api/fileAPI.js';
import { checkIDcard } from '@/api/hrAPI.js';
import { baseInfoTemplate , otherBaseInfoTemplate } from './data';


export default {
    components: {
        TableForm,
		AvatarPopup
    },
    props: {
        form: {
            type: Object,
            default(){
                return {};
            }
        },
        isDetail: {
            type: Boolean,
            default: true
        },
        disabledKeys: {
            type: Array,
            default(){
                return [];
            }
        }
    },
    data(){
        return {
            pickerOptions: {
                disabledDate (time) {
                    return time.getTime() > Date.now();
                },
            },
            template: [],
        }
    },
    created(){
        if(this.$getCurrentLib()){
            this.template = otherBaseInfoTemplate;
        }else{
            this.template = baseInfoTemplate;
        }
    },
    methods: {
        birthdayChange (val) {
            this.$set(this.form, 'age', this.calculateAge(val));
        },
        workTimeChange (val) {
            this.$set(this.form, 'workYear', this.calculateAge(val));
        },
        calculateAge (date) {
            if (date) {
                // 入职时间
                var birthDate = new Date(date);
                // 当前时间
                var today = new Date();
                var age = today.getFullYear() - birthDate.getFullYear();
                var m = today.getMonth() - birthDate.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                    age--;
                }

                if (age <= 1) {
                    return 1;
                } else {
                    return age;
                }
            } else {
                return '';
            }
        },
		openPopup() {
			this.$refs.popup.dialogVisible = true
		},
        upload() {
            this.$refs.inputFile.click();
        },
        inputChange () {
            const file = this.$refs.inputFile.files[0];

			const note = `上传登记头像`
	 		const path = '/hrms/file/upload/avatar'
	  		this.$store.commit('status/SET_LOG', {path, note})

            uploadAvatar({ file }).then(res => {
				this.$set(this.form, 'avatar',  res.filePath)
            }).finally(() => {
                this.$refs.inputFile.value = '';
            })
        },
        delFile () {
            this.form.avatar = '';
        },
        check () {
            checkIDcard({ idCard: this.form.idCard }).then(res => {
                this.$message.success(`您录入的【 ${this.form.idCard} 】不是组织结构内成员，可继续录入`)
            })
        },
    },
    mounted(){},
}
</script>

<style lang="less" scoped>
.user-image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .img-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: flex;

        img {
            max-width: 100%;
            max-height: 100%;
        }

        .del {
            position: absolute;
            right: 5px;
            top: 5px;
            color: #FF2525;
            display: none;
        }
    }

    .img-container:hover {
        .del {
            cursor: pointer;
            display: inline-block;
        }
    }

    .upload {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        i {
            margin-bottom: 10px;
        }
    }

}
.idCard{
    position: relative;
    .btn {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        color: #3291F8;
        padding: 0;
    }

}
</style>