<template>
	<div class="header">
		<div class="left">
			<div class="title">{{title}}</div>
			<div class="examine-status">
				<div v-if="detailInfo.examineStatus < 3"
					:style="{'background-color':EnumType.examineStatusColor[detailInfo.examineStatus]}">
					{{EnumType.examineStatus[detailInfo.examineStatus]}}
				</div>
				<div v-else :style="{'background-color':EnumType.examineResultColor[detailInfo.examineResult]}">
					已审批-{{EnumType.examineResult[detailInfo.examineResult]}}
				</div>
			</div>
			<div class="examine-user" v-if="detailInfo.examineStatus < 3">
				<span>审批人:</span>
				<!-- <span v-for="(item,index) in detailInfo.approver" :key="index">{{item.name}}</span> -->
				<span>{{detailInfo.approver && detailInfo.approver.map(item => item.name).join('、')}}</span>
			</div>
		</div>
		<div class="right" v-if="hasEdit">
			<el-button type="success" plain size="small" v-if="isedit" @click="editClick" v-permission="['/hrms/user/change/update']">修改</el-button>
		</div>
	</div>
</template>

<script>
import { updateRegister } from "@/api/hrAPI.js";
import { education } from "@/components/table/data";

export default {
  props: {
    detailInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  inject: ["updateForm"],
  data() {
    return {
      flowTypeMap: {
        REGISTER: "资料审核",
        CHANGE: "资料审核",
        ADJUST_POST: "调职审核",
        LEAVE: "离退审核",
      },
      userInfoId: this.$store.getters.userInfo.id,
    };
  },
  computed: {
    flowType() {
      return this.detailInfo.type;
    },
    title() {
      return this.flowTypeMap[this.flowType];
    },
    isedit() {
      return this.detailInfo.approver.some((i) => {
        return (i.id = this.userInfoId);
      });
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    hasEdit() {
      return (
        this.detailInfo.examineStatus !== 3 &&
        this.detailInfo.approver.find((item) => item.id === this.userInfo.id)
      );
    },
  },
  methods: {
    // 后端字段为枚举类型，不能为''
    checkEducationInfoList(formData) {
      education.forEach((item) => {
        if (item.require && !formData.educationInfoList[0][item.value]) {
          this.$set(formData.educationInfoList[0], item.value, null);
        }
      });
    },

    editClick() {
      this.updateForm(
        this.detailInfo.extra.formData,
        this.flowType,
        "修改信息",
        (formData, success) => {
          // this.checkEducationInfoList(formData);

          const params = {
            formData: formData,
            id: this.detailInfo.id,
            taskId: this.detailInfo.taskId,
            type: this.detailInfo.type,
          };

		 //  const {name} = this.detailInfo.extra.formData  修改前的名字
		 // formData.name 修改后的名字
		 // console.log(23231111143434, name, 'name') 
		  const path = '/hrms/user/change/update'
		  const note = `修改${formData.name}的${this.flowTypeMap[this.flowType]}信息`
		  this.$store.commit('status/SET_LOG', {path, note})
          updateRegister(params)
            .then(() => {
              this.$message.success("修改成功");
              this.detailInfo.extra.formData = formData;
              this.$parent.$parent.getTableData();
			  this.$parent.$parent.detailClick({id: this.detailInfo.id});
              success();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      );

      // this.updateForm(this.detailInfo.extra.formData,this.flowType,'修改信息').then(({formData,call}) => {
      //     const params = {
      //         formData: formData,
      //         id: this.detailInfo.id,
      //         taskId: this.detailInfo.taskId,
      //         type: this.detailInfo.type,
      //     }

      //     updateRegister(params).then(() => {
      //         this.$message.success('修改成功');
      //         this.detailInfo.extra.formData = formData;
      //         this.$parent.$parent.getTableData();
      //         call();
      //     }).catch(err => {
      //         console.log(err);
      //     })

      // }).catch(err => {
      //     console.log(err);
      // })
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.header {
  display: flex;
  justify-content: space-between;
  .left {
    display: flex;
    align-items: center;
    .title {
      color: #101010;
      font-size: 16px;
    }
    .examine-status {
      margin-left: 10px;
      border-radius: 5px;
      overflow: hidden;
      color: #fff;
      > div {
        padding: 5px;
      }
    }
    .examine-user {
      color: #101010;
      background-color: #f5f5f5;
      border-radius: 5px;
      height: 24px;
      line-height: 22px;
      padding: 0 5px;
      margin-left: 10px;
      border: 1px solid #bbb;
      box-sizing: border-box;
    }
  }
  .right {
    margin-right: 60px;
  }
}
</style>