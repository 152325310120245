import Vue from 'vue';

const sex = {
    MALE: '男',
    FEMALE: '女'
}

//  申请状态
const applyStatus = {
    REGISTER: '登记',
    CHANGE: '变更',
    ADJUST_POST: '调职',
    LEAVE: '离退',
}

const examineStatus = {
    1: '待中院审批',
    2: '待高院审批',
    3: '已审批'
}

const examineResult = {
    1: '通过',
    2: '拒绝',
}

const degree = {
    BACHELOR: '学士',
    MASTER: '硕士',
    DOCTOR: '博士'
}

const educationalSystem = {
    FULL_TIME: '全日制',
    ON_THE_JOB: '在职教育'
}

//  学历
const education = {
    POLYTECHNIC_SCHOOL: '中专',
    HIGH_SCHOOL: '高中',
    JUNIOR_COLLEGE: '大专',
    UNDERGRADUATE_COURSE: '本科',
    MASTER_POSTGRADUATE: '硕士研究生',
    DOCTOR_POSTGRADUATE: '博士研究生',
    OTHER: '其他',
}

//  审批状态颜色
const examineStatusColor = {
    1: '#FC9900',
    2: '#A411D1',
    3: '#27B148'
}

//  审批状态颜色
const examineResultColor = {
    1: '#52CC6F',
    2: '#FF2525',
}

//  人员变更状态
const changeStatus = {
    'PROMOTION': "晋升",
    'FLAT_TONE': "平调",
    'CALL_OUT': "调出",
    'RETIRE': "退休",
    'DEPART': "辞职",
    'OTHER': "其他（开除、辞退、死亡）",
}

//  人员状态
const userStatus = {
    'BE_ON_THE_JOB': '在职',
    'CALL_OUT': '调出',
    'DEPART': '辞职',
    'RETIRE': '退休',
    'OTHER': '其他',
}

const orgType = {
    'SENIOR': '高院',
    'INTERMEDIATE': '中院',
    'JUNIOR': '基层院',
    'DEPT': '部门',
}

//  专业
const major = {
    'LEGAL_PROFESSION': '法律专业',
    'OTHER': '其他专业'
}

//  政治面貌
const ZZMM = {
    'CPC': '中共党员',
    'CY': '共青团员',
    'DNC': '民主党派',
    'OTHER': '其他',
}

export const EnumType = {
    sex,
    applyStatus,
    examineStatus,
    examineStatusColor,
    examineResult,
    examineResultColor,
    education,
    degree,
    educationalSystem,
    changeStatus,
    userStatus,
    orgType,
    major,
    ZZMM
}

Vue.prototype.EnumType = EnumType;

const changeStatusList = Object.keys(changeStatus).map(key => {
    return {
        label: changeStatus[key],
        value: key
    }
})

const userStatusList = Object.keys(userStatus).map(key => {
    return {
        label: userStatus[key],
        value: key
    }
})

const orgTypeList = Object.keys(orgType).map(key => {
    return {
        label: orgType[key],
        value: key
    }
})

const applyStatusList = Object.keys(applyStatus).map(key => {
    return {
        label: applyStatus[key],
        value: key
    }
})

const examineStatusList = Object.keys(examineStatus).map(key => {
    return {
        label: examineStatus[key],
        value: key
    }
})

const examineResultList = Object.keys(examineResult).map(key => {
    return {
        label: examineResult[key],
        value: key
    }
})

const degreeList = enumToOptions(degree);
const educationList = enumToOptions(education);
const educationalSystemList = enumToOptions(educationalSystem);
const majorList = enumToOptions(major);
const ZZMMList = enumToOptions(ZZMM);

export const EnumList = {
    changeStatusList,
    userStatusList,
    orgTypeList,
    applyStatusList,
    examineStatusList,
    examineResultList,
    degreeList,
    educationList,
    educationalSystemList,
    majorList,
    ZZMMList
}

Vue.prototype.EnumList = EnumList;

function enumToOptions(map){
    return Object.keys(map).map(key => {
        return {
            label: map[key],
            value: key
        }
    })
}