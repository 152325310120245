<template>
    <el-form ref="form" :model="form" label-width="80px" label-position="top" :rules="rules" size="small">
        <el-row :gutter="30">
            <el-col :span="8">
                <el-form-item label="干部人员" prop="name">
                    <el-input :disabled="true" v-model="form.name"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="离退类型" prop="type">
                    <el-select v-model="form.type">
                        <el-option v-for="item in changeStatusList" :key="item.value" :value="item.value" :label="item.label"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="离退日期" prop="adjustTime">
                    <el-date-picker
                        v-model="form.adjustTime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        clearable>
                    </el-date-picker>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="30">
            <el-col :span="12">
                <el-form-item :label="$replaceText('现任部门')" prop="currentOrgId">
					<!-- <el-input v-model="positionText" disabled></el-input> -->
                    <MyCascader v-model="form.currentOrgId" :options="orgList" size="small" :props="cascaderProps" placeholder="" disabled clearable></MyCascader>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="现任职务" prop="currentPositionIds">
                    <MySelectGroup v-model="form.currentPositionIds" :options="jobList" disabled placeholder=""> </MySelectGroup>
                </el-form-item>
            </el-col>
        </el-row>
        <el-form-item label="离退原因" prop="adjustReason">
            <el-input :rows="3" resize="none" type="textarea" v-model="form.adjustReason"></el-input>
        </el-form-item>
        <el-form-item label="呈报单位" prop="reportUnit">
            <el-input :rows="3" resize="none" type="textarea" v-model="form.reportUnit"></el-input>
        </el-form-item>
        <el-form-item label="审批机关意见" prop="approvalAuthorityOpinion">
            <el-input :rows="3" resize="none" type="textarea" v-model="form.approvalAuthorityOpinion"></el-input>
        </el-form-item>
        <el-form-item label="行政机关任免意见" prop="positionChangeOpinion">
            <el-input :rows="3" resize="none" type="textarea" v-model="form.positionChangeOpinion"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="notes">
            <el-input :rows="3" resize="none" type="textarea" v-model="form.notes"></el-input>
        </el-form-item>

        
    </el-form>
</template>

<script>
import { getJobList , getOrgList } from '@/api/hrAPI.js';
import { getIdListInTree, getCourtByOrgId } from '@/utils/handle.js';

export default {
    props: {
        form: {
            type: Object,
            default(){
                return {};
            }
        },
    },
    data(){
        return {
            jobList: [],
            orgList: [],
            rules: {
                type: [
                    { required: true, message: '请选择离退类型', trigger: 'change' },
                ]
            },
            orgIdList: [],
            currentOrgIdList: [],
			positionText: '',
            cascaderProps: {
                label: 'name',
                value: 'id',
                checkStrictly: true
            },
        }
    },
    computed: {
        changeStatusList(){
			const values = ['CALL_OUT', 'RETIRE', 'DEPART', 'OTHER']
			const list = this.EnumList.changeStatusList.filter(item => values.includes(item.value))
			// list.forEach(item => {
			// 	if(item.value === 'DEPART') {
			// 		item.label = this.$replaceText('辞职')
			// 	}
			// })
            return list
        }
    },
    created(){
        getJobList().then(res => {
            this.jobList = this.initJobOptions(res);
        })
        getOrgList().then(orgs => {
			const { currentOrgId } = this.form
			const res = {courts: [], pid: ''}
			getCourtByOrgId(orgs, currentOrgId, res)
			// this.positionText = res.courts.map(court => court.name).join('/')
			const court = res.courts.find(court => court.pid === res.pid || court.id === res.pid)
			this.orgList = court ? [court] : []
        })
    },
    watch: {
        // orgList: {
        //     handler(list){
        //         this.currentOrgIdList = getIdListInTree(list,this.form.currentOrgId);
        //     },
        // },
        // 'form.originalOrgId': {
        //     handler(val){
        //         this.orgIdList = getIdListInTree(this.orgList,val);
                
        //     },
        // },
    },
    methods: {
        submit(){
            return new Promise((resolve,reject) => {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        resolve();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                })

            })
        },
        currentOrgIdListChange(list){
            if(list.length){
                this.form.currentOrgId = list[list.length - 1];
            }
        },
        resetForm() {
            this.$refs.form.resetFields();
        },
        initJobOptions(res){
            const list = [];
            res.forEach(job => {
                let target = list.find(item => item.label === job.typeName);
                if(!target){
                    target = {
                        label: job.typeName,
                        options: [],
                    }
                    list.push(target);
                }
                target.options.push({
                    label: job.name,
                    value: job.id
                })
                
            })
            return list;
        }
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
/deep/ .el-form-item__label{
    line-height: 1;
}
/deep/ .el-select{
    width: 100%;
}
/deep/ .el-date-editor{
    width: 100%;
}
/deep/ .el-cascader{
    width: 100%;
}
</style>