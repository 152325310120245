import service from '@/utils/service';

export function getUserList(params){
    return service.get('/user/org/list-users',{
        params
    })
}

export function getConfigs(params){
    return service.get('/basic/config/get',{
        params,
    })
}

//  图片ocr识别-单文件同步
export function ocrFile(data){
    return service.post('/basic/ocr/recognize',data,{
        headers: {'Content-Type': 'multipart/form-data'}
    })
}

export function logout(params){
    return service.get('/user/logout',{
        params,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    })
}

export function getUserInfo(params){
    return service.get('/user/base/base-info',{
        params,
    })
}

//  字典信息
export function getDict(params){
    return service.get('/caseinfo/dict/tree',{
        params,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    })
}
