

import service from '@/utils/service.js';

export function getStatus(params) {
	return service.get('/hrms/statistic/query-user-situation', {
		params
	})
}

export function getEducation(params) {
	return service.get('/hrms/statistic/query-qualification-range', {
		params
	})
}

export function getAge(params) {
	return service.get('/hrms/statistic/query-age-range', {
		params
	})
}

export function getWorkTime(params) {
	return service.get('/hrms/statistic/query-working-years', {
		params
	})
}

export function getIncrease(params) {
	return service.get('/hrms/statistic/query-new-cadre', {
		params
	})
}

// 统计分析-干部职级统计(在职)
export function getJobRank(params) {
	return service.get('/hrms/statistic/query-job-rank-in-court', {
		params
	})

}
// 统计分析-干部性别统计
export function getSexStatistic(params) {
	return service.get('/hrms/statistic/query-sex-in-court', {
		params
	})
}




// 首页-干部学历统计
export function getEduHomecation(params) {
	return service.get('/hrms/statistic/index/education-info', {
		params
	})
}
// 首页-干部年龄统计
export function getHomeAge(params) {
	return service.get('/hrms/statistic/index/query-age', {
		params
	})
}
// 首页-干部工作年限统计
export function getWoHomerkTime(params) {
	return service.get('/hrms/statistic/index/query-working-range', {
		params
	})
}

// 首页-法官等级统计
export function getInCourt(params) {
	return service.get('/hrms/statistic/index/query-judge-rank', {
		params
	})
}
// 首页-新增干部动态
export function educationAddInfo() {
	return service.get('/hrms/statistic/index/register-info', {
		params
	})

}


// 首页-干部平均年龄,工作年限,学历占比统计
export function proportion() {
	return service.get('/hrms/statistic/index/query-general-info', {

	})
}

// 首页-用户在职状态查询
export function getCadreStatus() {
	return service.get('/hrms/statistic/index/query-status', {

	})
}

// 首页-新增干部动态
export function getCadreAddStatus() {
	return service.get('/hrms/statistic/index/register-info', {

	})
}

// 法官等级统计
export function queryJudgeRank(params) {
	return service.get('/hrms/statistic/query-rank-in-court', {
		params
	})
}

// 统计报表-各部门人数统计表
export function reportDeptCount(params) {
	return service.post('/hrms/report/query-dept-count', params, {
		headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
	})
}

// 统计报表-法院工作人员基本情况统计表
export function reportBaseInfo(params) {
	return service.post('/hrms/report/query-base-info', params, {
		headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
	})
}

// 统计报表-法院工作人员最高学历情况统计表
export function reportEducation(params) {
	return service.post('/hrms/report/query-highest-qualification', params, {
		headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
	})
}

// 统计报表-法院工作人员全日制学历情况统计表
export function reportEducationFullTime(params) {
	return service.post('/hrms/report/query-full-time', params, {
		headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
	})
}

// 统计报表-数字内页用户信息
export function queryInnerPage(params) {
	return service.post('/hrms/report/query-user-infos', params, {
		headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
	})
}

export function queryInnerPageBlob(data, params) {
	return service.post('/hrms/report/query-user-infos', data, {
		params,
		headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
		responseType: 'blob'
	})
}
