export function deepAssign(target,source){          //  深度合并对象
    // console.log(target,source);
    // debugger
    // console.log(JSON.parse(JSON.stringify(target)),source);
    if(source.constructor === Object){
        
        if(target.constructor === Object){
            Object.keys(source).forEach(sourceKey => {
                // debugger
                const targetItem = target[sourceKey];
                const sourceItem = source[sourceKey];
                const targetType = targetItem && targetItem.constructor;
                const sourceType = sourceItem && sourceItem.constructor;
    
                if(targetType === Object && sourceType === Object){
                    deepAssign(targetItem,sourceItem);                  //  都为对象的时候才会进行递归合并
                }else if(targetType === Array && sourceType === Array){
                    deepAssign(targetItem,sourceItem);
                }else {
                    if(source[sourceKey]){
                        target[sourceKey] = source[sourceKey];
                    }
                }
            })
        }else{
            target = source;
        }
        return target;
    }else if(source.constructor === Array){
        if(target.constructor === Array){
            for (let i = 0; i < source.length; i++) {
                let targetItem = target[i];
                const sourceItem = source[i];
                const targetItemType = targetItem && targetItem.constructor;
                const sourceItemType = sourceItem && sourceItem.constructor;
                
                if(targetItemType === Object && sourceItemType === Object){
                    deepAssign(targetItem,sourceItem);
                }else if(targetItemType === Array && sourceItemType === Array){
                    deepAssign(targetItem,sourceItem);
                }else{
                    target.splice(i,0,sourceItem);
                    // target[i] = sourceItem;
                }
            }
        }else{
            target = source;
        }
    }
}

export function batchDel(arr,call){        //  数组批量删除元素
    for (let i = 0; i < arr.length; i++) {
        let flag = call(arr[i],i,arr);      //  是否删除
        if(flag){
            arr.splice(i,1);
            i--;
        }
    }
}

export function traverse(arr,cb,children = 'children'){     //  递归遍历数据
    if(arr instanceof Array){
        let flag = false;
        function recursion(arr){
            for (let index = 0; index < arr.length; index++) {
                const item = arr[index];
                
                if(flag) return;
                if(item[children] && item[children].length > 0){
                    recursion(item[children]);
                }
                if(cb(item,index,arr)){
                    flag = true;
                }
            }
        }
        recursion(arr);
    }
}

export function unique(arr,cb){         //  条件去重
    for (let i = 0; i < arr.length; i++) {
        let current = arr[i];
        for (let j = i + 1; j < arr.length; j++) {
            let next = arr[j];
            let flag = cb(current,next);
            if(flag){
                // console.log(i,j);
                // console.log(JSON.parse(JSON.stringify(arr)));
                arr.splice(j,1);
                j --;
            }
        }
        
    }
}

//  数组转成tree结构数据
export function dataToTree(data){       
    let list = JSON.parse(JSON.stringify(data));
    let map = {};
    let tree = [];

    list.forEach(item => {
        map[item.id] = item;
        item.children = null;
    });
    // console.log(map);
    list.forEach(item => {
        let parent = map[item.pid];
        if(parent){
            parent.children ? parent.children : parent.children = [];
            parent.children.push(item);
        }else{
            tree.push(item);
        }
    })
    return tree;
}

export function flat(list, map) {
	let i = 0
	do {
		const item = list[i]
		if(item) {
			item.maxIndex = list.length
			if (item.children && item.children.length) {
				map[item.id] = item
				flat(item.children, map)
			}
		}
		i++
	} while(i < list.length)
}


export function getIdListInTree(arr,id,getKey = 'id'){
    const map = {};
    traverse(arr,item => {
        map[item.id] = item;
    })
    // console.log(map);
    let temp = map[id];
    if(temp){
        const result = [temp[getKey]];

        while(temp && temp.pid !== '0'){
            // console.log(temp);
            temp = map[temp.pid];
            if(temp){
                result.push(temp[getKey]);
            }
        }

        return result.reverse();
    }else{
        return [];
    }
}

export function filterTree(arr,call){
    const list = [];
    const map = {};
    traverse(arr,item => {
        map[item.id] = item;
        if(call(item)){
            list.push(item);
        }
    })
    // console.log(list);
    const result = [];
    list.forEach(item => {
        let temp = map[item.id];
        result.push(temp);
        while(!(temp && temp.pid === "0")){
            temp = map[temp.pid];
            result.push(temp);
        }

    })
    // console.log(result);
    unique(result,(a,b) => {     //  去重
        return a.id === b.id;
    })
    const tree = dataToTree(result);
    return tree;
}

export function copyTree(arr,callback){
    const newTree = [];
    function recursion(arr,nArr){
        arr.forEach(child => {
            let newData;
            if(callback && callback instanceof Function){
                newData = callback(child);
                
            }else{
                newData = JSON.parse(JSON.stringify(child));      //  考虑到不是所有类型都能进行拷贝，特殊类型必须要使用callback
            }
            if(newData){
                nArr.push(newData);
            }

            if(child.children && child.children.length > 0){
                if(newData){
                    newData.children = [];
                    recursion(child.children,newData.children);
                }
            }
        })
    }
    recursion(arr,newTree);
    return newTree;
}

export function getSetRes(arr1,arr2,call,type){
    // intersection,union,DifferenceSet,Complement set
    // 交集,并集,差集,补集
    const res = [];
    for (let i = 0; i < arr1.length; i++) {
        const a = arr1[i];
        let flag = false;
        for (let j = 0; j < arr2.length; j++) {
            const b = arr2[j];
            flag = call(a,b);
            if(flag){       //  代表相交
                
            }else{

            }
        }
    }
}

// pid是orgId的父id
export function getCourtByOrgId(orgs, orgId, res) {
	// const res = {courts: [], pid: {}}
	if (orgs && orgs.length) {
		for (let index = 0; index < orgs.length; index++) {
			const elem = orgs[index];
			if (elem.id === orgId) {
				res.courts.unshift(elem)
				res.pid = elem.pid
				break
			} else {
				getCourtByOrgId(elem.children, orgId, res)
			}

			if (res.pid) {
				if (res.courts[0].type === 'DEPT') {
					res.courts.unshift(elem)
				}

				break
			}
			res.courts = []
		}
	}
}

export function getCourtByCourtId(orgs, courtId, res) {
	if (orgs && orgs.length) {
		for (let index = 0; index < orgs.length; index++) {
			const elem = orgs[index];
			if (elem.courtId === courtId) {
				res.court = elem
				break
			} else {
				getCourtByCourtId(elem.children, courtId, res)
			}

			if (res.court) break
		}
	}
}