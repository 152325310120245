import service from '@/utils/service';

//  用户流程信息
export function flowUserList(params){
    return service.get('/hrms/user/change/search-page',{
        params
    })
}

//  删除用户
export function delUser(params){
    return service.delete('/hrms/user/remove',{
        params
    })
}

//  用户信息列表
export function userList(params){
    return service.get('/hrms/user/search-page',{
        params
    })
}

//  用户信息详情
export function userDetail(params){
    return service.get('/hrms/user/query-user-by-id',{
        params
    }).then(formData => {
        if(formData){
            formData.assessmentSituationsInfo = formData.assessmentSituationsInfo || {};
            formData.presentationInfo = formData.presentationInfo || {};
        }
        return formData;
    })
}

// 系统设置查询职务列表
export function getSysList(params){
    return service.get('/hrms/position/query-list/sys',{
        params
    })
}

//  登记页查询职务信息
export function getJobList(params){
    return service.get('/hrms/position/query-list/register',{
        params
    })
}

//  组织信息(带人员)
export function getOrgList(params){
    return service.get('/hrms/org/query-tree-by-user',{
        params
    })
}

//  查询所有组织信息
export function getAllOrgList(params,addPath){
    return service.get(`/hrms${addPath || ''}/org/tree`,{
        params
    })
}

//  添加组织
export function createOrg(data){
    return service.post('/hrms/org/add',data,{
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
}

//  修改组织
export function updateOrg(data){
    return service.post('/hrms/org/modify',data,{
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, 
    })
}

//  删除组织
export function delOrg(params){
    return service.delete('/hrms/org/delete',{
        params
    })
}

// 移动组织
export function moveOrg(data) {
	return service.post('/hrms/org/move-org', data, {
		headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
	})
}

//  获取人员详情
export function getFormData(params){
    return service.get('/hrms/user/change/query-by-id',{
        params
    }).then(res => {
        const formData = res.extra.formData;
        if(formData){
            formData.assessmentSituationsInfo = formData.assessmentSituationsInfo || {};
            formData.presentationInfo = formData.presentationInfo || {};
        }
        return res;
    })
}

//  删除表单信息
export function delFormData(params){
    return service.delete('/hrms/user/change/remove',{
        params
    })
}

//  获取草稿箱信息
export function getDraftList(params){
    return service.get('/hrms/user/change/query-drafts',{
        params
    })
}

//  获取草稿箱信息
export function delDraft(params){
    return service.delete('/hrms/user/change/delete-drafts',{
        params
    })
}

//  人员信息提交
export function createRegister(data){
    return service.post('/hrms/user/change/register-create',data,{
        
    })
}

//  人员信息修改
export function updateRegister(data){
    return service.post('/hrms/user/change/update',data,{
        
    })
}

//  人员变更申请
export function createChange(data){
    return service.post('/hrms/user/change/leave-create',data,{
        
    })
}

//  日志信息
export function getLogList(params){
    return service.get('/hrms/log/query-page',{
        params
    })
}

//  添加职务
export function addJob(data){
    return service.post('/hrms/position/add',data,{
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        
    })
}

//  修改职务
export function editJob(data){
    return service.post('/hrms/position/modify',data,{
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        
    })
}

//  删除职务
export function delJob(params){
    return service.delete('/hrms/position/delete',{
        params
    })
}

//  新增职务类型
export function addJobType(data){
    return service.post('/hrms/position/dict/add',data,{
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        
    })
}
//  移动职务类型
export function moveJobType(data){
    return service.post('/hrms/position/type/move',data,{
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        
    })
}

//  修改职务类型
export function editJobType(data){
    return service.post('/hrms/position/type/modify',data,{
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        
    })
}

//  删除职务类型
export function delJobType(params){
    return service.delete('/hrms/position/type/delete',{
        params
    })
}

//  数据审核
export function examineFlow(data){
    return service.post('/hrms/user/change/examine-flow',data,{
        
    })
}

//  变动记录
export function changeHistory(params){
    return service.get('/hrms/user/change/history',{
        params
    })
}

//  人员编辑
export function updateFlowUser(data) {
	return service.post('/hrms/user/update', data)
}
 
//  人员变更申请
export function createFlowUser(data){
    return service.post('/hrms/user/change/change-create',data,{
        
    })
}

//  职务异动申请
export function createJobChangeUser(data){
    return service.post('/hrms/user/change/leave-create',data,{
        
    })
}

//  获取审批人员信息
export function examineUserList(params,addPath){
    return service.get(`/user/user/hrms${addPath}/user-info`,{
        params
    })
}

//  根据条件查询组织
export function getCourtList(params){
    return service.get('/user/org/list',{
        params
    })
}

//  校验用户身份证号
export function checkIDcard(params){
    return service.get('/hrms/user/change/check-id-card',{
        params
    })
}


