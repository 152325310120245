import Vue from 'vue'
import VueRouter from 'vue-router'
import { getStatus, getEducation, getAge, getWorkTime, getIncrease , queryJudgeRank , getJobRank , getSexStatistic } from '@/api/statisticAPI.js';
import store from '@/store';

Vue.use(VueRouter)
// console.log(store.getters.configMap['platformServiceUrl'],'地址');
export const routes = [
	{
		path: '/',
		redirect: '/entrance'
	},
	{
		path: '/demo',
		component: () => import('@/components/demo.vue')
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login'),
	},
	{
		path: '/entrance',
		name: 'entrance',
		component: () => import('@/views/entrance'),

	},
]
export const speRoutes = [		//	特殊路由，title不一样，
	// {
	// 	path: '/register/cadre',
	// 	name: 'registerCadre',
	// 	component: () => import('@/views/register'),
	// 	meta: {
	// 		title: '信息录入',
	// 		url: '/hrms/cadre/user/change/register-create'
	// 	}
	// },
	// {
	// 	path: '/register/dean',
	// 	name: 'registerDean',
	// 	component: () => import('@/views/register'),
	// 	meta: {
	// 		title: '信息录入',
	// 		url: '/hrms/dean/user/change/register-create'
	// 	}
	// },
	{
		path: '/person/cadre',
		name: 'personCadre',
		component: () => import('@/views/person'),
		meta: {
			title: '成长档案',
			url: '/hrms/cadre/user/search-page,/hrms/cadre/org/query-tree-by-user'
		},
	},
	{
		path: '/person/dean',
		name: 'personDean',
		component: () => import('@/views/person'),
		meta: {
			title: '培养档案',
			url: '/hrms/dean/user/search-page,/hrms/dean/org/query-tree-by-user'
		},
	},

];

// 统计分析-干部职级统计headers


const header1 = [
	{
		label: '组织名称',
		value: 'courtName'
	},
	{
		label: '一级调研员',
		value: 'FirstLevelResearcher'
	},
	{
		label: '二级调研员',
		value: 'SecondLevelResearcher'
	},
	{
		label: '三级调研员',
		value: 'ThirdLevelResearcher'
	},
	{
		label: '四级调研员',
		value: 'FourthLevelResearcher'
	},
	{
		label: '合计',
		value: 'totalCount'
	}
]


const header2 = [
	{
		label: '组织名称',
		value: 'courtName'
	},
	{
		label: '院领导',
		value: 'InstituteLeader'
	},
	{
		label: '中层干部',
		value: 'MiddleCadre'
	},
	{
		label: '其他',
		value: 'other'
	},
	{
		label: '合计',
		value: 'totalCount'
	}
]

export const asyncRoutes = [
	{
		path: '/layout',
		name: 'layout',
		component: () => import('@/components/layout'),
		children: [
			{
				path: '/home',
				name: 'home',
				component: () => import('@/views/home'),
				meta: {
					title: '首页',
					url: `
					/hrms/statistic/index/query-judge-rank,
					/hrms/statistic/index/education-info,
					/hrms/statistic/index/query-age,
					/hrms/statistic/index/query-working-range,
					/hrms/statistic/index/query-general-info,
					/hrms/statistic/index/query-status,
					`
				}
			},
			{
				path: '/register',
				name: 'register',
				component: () => import('@/views/register'),
				meta: {
					title: '信息录入',
					url: '/hrms/user/change/register-create'
				}
			},
			{
				path: '/examine',
				name: 'examine',
				component: () => import('@/views/examine'),
				meta: {
					title: '档案审核',
					url: '/hrms/user/change/search-page'
				}
			},
			{
				path: '/person',
				name: 'person',
				component: () => import('@/views/person'),
				meta: {
					title: '人员管理',
					url: '/hrms/user/search-page,/hrms/org/query-tree-by-user'
				},
			},
			{
				path: '/statistic',
				name: 'statistic',
				component: () => import('@/views/statistic'),
				meta: {
					title: '统计分析',
					url: 	`
							/hrms/statistic/query-user-situation,
							/hrms/statistic/query-rank-in-court,
							/hrms/statistic/query-job-rank-in-court,
					        /hrms/statistic/query-qualification-range,
							/hrms/statistic/query-sex-in-court,
							/hrms/statistic/query-age-range,
							/hrms/statistic/query-working-years,
							/hrms/statistic/query-new-cadre
							`

				},
				children: [
					{
						path: '/statistic/status',
						name: 'status',
						component: () => import('@/views/statistic/template'),
						meta: {
							label: '各法院干部情况统计',
							url: '/hrms/statistic/query-user-situation'
						},
						props: {
							headers: [
								{
									label: '组织名称',
									value: 'courtName'
								},
								{
									label: '在职',
									value: 'beOnTheJobCount'
								},
								{
									label: '辞职',
									value: 'departCount'
								},
								{
									label: '退休',
									value: 'retireCount'
								},
								{
									label: '调出',
									value: 'callOutCount'
								},
								{
									label: '其他',
									value: 'restCount'
								},
								{
									label: '合计',
									value: 'totalCount'
								},
							],
							paramsHeaders: [
								{
									label: '组织名称',
									value: 'courtName'
								}
							],
							params: {
								courtName: ''
							},
							getTableData: getStatus,
						}
					},

					{
						path: '/statistic/grade',
						name: 'grade',
						component: () => import('@/views/statistic/template'),
						meta: {
							label: '法官等级统计',
							url: '/hrms/statistic/query-rank-in-court'
						},
						props: {
							headers: [
								{
									label: '组织名称',
									value: 'courtName'
								},
								{
									label: '一级法官',
									value: 'FirstSeniorJudge'
								},
								{
									label: '二级法官',
									value: 'SecondSeniorJudge'
								},
								{
									label: '三级法官',
									value: 'ThirdSeniorJudge'
								},
								{
									label: '四级法官',
									value: 'FourthSeniorJudge'
								},
								{
									label: '未入额',
									value: 'noRank'
								},
								{
									label:'合计',
									value:'totalCount'
								}
							],
							paramsHeaders: [
								{
									label: '组织名称',
									value: 'courtName'
								}
							],
							params: {
								courtName: ''
							},
							getTableData: queryJudgeRank,
						}
					},
					{
						path: '/statistic/rank',
						name: 'rank',
						component: () => import('@/views/statistic/template'),
						meta: {
							label: '干部职级统计',
							url: '/hrms/statistic/query-job-rank-in-court'
						},
						props:(route) => {
							return {
								headers: route.name === 'rank' ? header1 : header2,
								paramsHeaders: [
									{
										label: '组织名称',
										value: 'courtName'
									}
								],
								params: {
									courtName: ''
								},
								getTableData: getJobRank,
							}
						}
					},
					{
						path: '/statistic/education',
						name: 'education',
						component: () => import('@/views/statistic/template'),
						meta: {
							label: '干部学历统计',
							url: '/hrms/statistic/query-qualification-range'
						},
						props: {
							headers: [
								{
									label: '组织名称',
									value: 'courtName'
								},
								{
									label: '研究生',
									value: 'postgraduateCount'
								},
								{
									label: '本科',
									value: 'undergraduateCourseCount'
								},
								{
									label: '大专',
									value: 'juniorCollegeCount'
								},
								{
									label: '大专以下',
									value: 'beforeJuniorCollegeCount'
								},
								{
									label: '合计',
									value: 'totalCount'
								},
							],
							paramsHeaders: [
								{
									label: '组织名称',
									value: 'courtName'
								}
							],
							params: {
								courtName: ''
							},
							getTableData: getEducation,
						}
					},
					{
						path: '/statistic/sex',
						name: 'sex',
						component: () => import('@/views/statistic/template'),
						meta: {
							label: '干部性别统计',
							url: '/hrms/statistic/query-sex-in-court'
						},
						props: {
							headers: [
								{
									label: '组织名称',
									value: 'courtName'
								},
								{
									label: '男性',
									value: 'MALE'
								},
								{
									label: '女性',
									value: 'FEMALE'
								},
								{
									label:'合计',
									value:'totalCount'
								}
							],
							paramsHeaders: [
								{
									label: '组织名称',
									value: 'courtName'
								}
							],
							params: {
								courtName: ''
							},
							getTableData: getSexStatistic,
						}
					},
					{
						path: '/statistic/age',
						name: 'age',
						component: () => import('@/views/statistic/template'),
						meta: {
							label: '干部年龄统计',
							url: '/hrms/statistic/query-age-range'
						},
						props: {
							headers: [
								{
									label: '组织名称',
									value: 'courtName'
								},
								{
									label: '35岁以下',
									value: 'lessThenThirtyFive'
								},
								{
									label: '36-40岁',
									value: 'thirtySixToForty'
								},
								{
									label: '41-45岁',
									value: 'fortyOneToFortyFive'
								},
								{
									label: '46-50岁',
									value: 'fortySixToFifty'
								},
								{
									label: '51-55岁',
									value: 'fiftyOneToFiftyFive'
								},
								{
									label: '56-60岁',
									value: 'fiftySixToSixty'
								},
								{
									label: '61岁以上',
									value: 'moreThenSixtyOne'
								},
								{
									label: '合计',
									value: 'totalCount'
								},
							],
							paramsHeaders: [
								{
									label: '组织名称',
									value: 'courtName'
								}
							],
							params: {
								courtName: ''
							},
							getTableData: getAge,
						}
					},
					{
						path: '/statistic/workTime',
						name: 'workTime',
						component: () => import('@/views/statistic/template'),
						meta: {
							label: '干部工作年限统计',
							url: '/hrms/statistic/query-working-years'
						},
						props: {
							headers: [
								{
									label: '组织名称',
									value: 'courtName'
								},
								{
									label: '2年以下',
									value: 'lessThenTwo'
								},
								{
									label: '2-4年',
									value: 'twoToFour'
								},
								{
									label: '4-6年',
									value: 'fourToSix'
								},
								{
									label: '6-8年',
									value: 'sixToEight'
								},
								{
									label: '8-10年',
									value: 'eightToTen'
								},
								{
									label: '10年以上',
									value: 'moreThenTen'
								},
								{
									label: '合计',
									value: 'totalCount'
								},
							],
							paramsHeaders: [
								{
									label: '组织名称',
									value: 'courtName'
								}
							],
							params: {
								courtName:''
							},
							getTableData: getWorkTime,
						}
					},
					{
						path: '/statistic/increase',
						name: 'increase',
						component: () => import('@/views/statistic/template'),
						meta: {
							label: '新增干部统计',
							url: '/hrms/statistic/query-new-cadre'
						},
						props: {
							headers: [
								{
									label: '月份',
									value: 'month'
								},
								{
									label: '新增干部数量',
									value: 'count'
								},
							],
							paramsHeaders: [
								{
									label: '年份',
									value: 'year',
									type: 'datePicker'
								}
							],
							hasTotal: true,
							params: {
								year: new Date().getFullYear().toString(),
							},
							getTableData: getIncrease,
						}
					},
				]
			},
			{
				path: '/reportForm',
				name: 'reportForm',
				component: () => import('@/views/reportForm'),
				meta: {
					title: '统计报表',
					url: '/hrms/report/query-base-info, /hrms/report/query-highest-qualification, /hrms/report/query-full-time'
				}
			},
		]
	},
	{
		path: '/layout-system',
		name: 'layoutSystem',
		component: () => import('@/components/layout'),
		children: [
			{
				path: '/system',
				name: 'system',
				component: () => import('@/views/system'),
				redirect: '/system/org',
				meta: {
					title: '系统设置',
					url: '/hrms/org/tree,/hrms/cadre/org/tree,/hrms/dean/org/tree,/hrms/position/query-list/sys,/hrms/cadre/position/query-list/sys,/hrms/dean/position/query-list/sys,/hrms/log/query-page,/hrms/cadre/log/query-pag,/hrms/dean/log/query-page'
				},
				children: [
					{
						path: '/system/org',
						name: 'org',
						component: () => import('@/views/system/org'),
						meta: {
							label: '组织结构',
							url: '/hrms/org/tree,/hrms/cadre/org/tree,/hrms/dean/org/tree'
						},
					},
					{
						path: '/system/duties',
						name: 'duties',
						component: () => import('@/views/system/duties'),
						meta: {
							label: '职务管理',
							url: '/hrms/position/query-list/sys,/hrms/cadre/position/query-list/sys,/hrms/dean/position/query-list/sys'
						},
					},
					{
						path: '/system/log',
						name: 'log',
						component: () => import('@/views/system/log'),
						meta: {
							label: '操作日志',
							url: '/hrms/log/query-page,/hrms/cadre/log/query-page,/hrms/dean/log/query-page'
						},
					},
					{
						path: '/system/user',
						name: 'user',
						link:'',
						meta: {
							label: '用户管理',
							url: '/user/org/list'
						}
					},
				]
			},
		]

	},
	{
		path: '/need-handle',
		name: 'needHandle',
		component: () => import('@/views/needHandle'),
		meta: {
			url: '/hrms/user/change/search-page'
		}
	},
	{
		path: '/user-detail',
		name: 'userDetail',
		component: () => import('@/views/userDetail'),
		meta: {
			url: '/hrms/user/change/search-page'
		}
	},
	{
		path: '/contrast',
		name: 'contrast',
		component: () => import('@/views/contrast'),
		meta: {
			url: '/hrms/user/change/search-page'
		}
	},
]


const router = new VueRouter({
	routes: routes,
	mode: 'history'
})
// console.log(router);
// // 防止连续点击多次路由报错
// let routerPush = Router.prototype.push;
// let routerReplace = Router.prototype.replace;

export function resetRouter (newRoutes) {
	const newRouter = new VueRouter({
		routes: routes.concat(newRoutes),
		mode: 'history'
	})
	// console.log(newRoutes);
	router.matcher = newRouter.matcher // reset router
	router.options = newRouter.options // reset router
	// router.history.setupListeners();
	// console.log(router.history);
	router.history.setupListeners();
	return router
}


export default router
