import router from './router'
import store from './store'
import NProgress from 'nprogress'                  // progress bar
import 'nprogress/nprogress.css'                   // progress bar style
NProgress.configure({ showSpinner: false })        // NProgress Configuration

import { generateRoutes } from '@/router/createRoutes.js';

const whiteList = ["login"];	//	白名单
const toHrmsWhiteList = [		//	跳转路由的时候
	'/need-handle',
	'/user-detail',
	'/contrast',
	'/person',		
];

let hasRoutes = false;
router.beforeEach(async (to, _, next) => {
	document.title = '河北法院干部政治素质和业绩档案管理系统';
	NProgress.start()
	if(to.query && to.query.WHRP_TOKEN){
        store.commit('common/SET_TOKEN',to.query.WHRP_TOKEN);
    }
	// if (process.env.NODE_ENV === 'development' && hasLogin === false) {
	// 	await login();
	// 	hasLogin = true;
	// }

	if(!whiteList.includes(to.name)){
		if(!hasRoutes){
			await store.dispatch('common/getUserInfo');
			await store.dispatch('common/getConfigMap');
			await store.dispatch('common/getDictMap');
			generateRoutes();					
			hasRoutes = true;
			next({ path: to.fullPath,replace: true });		//	每次生成之后都会添加一条history历史记录，添加replace
		}
	}else{
		// console.log(111111111111);
		hasRoutes = false;
	}
	// console.log(to);
	//	跳转的时候添加hrms后面的path
	const currentRoute = router.currentRoute;
	if(currentRoute.path.includes('cadre') && toHrmsWhiteList.includes(to.path)){		//	不能用name，有可能渲染不出来前面的routes
		// console.log('cadre-----------------');
		next({
			path: `${to.path}/cadre`,
			query: to.query,
		});
		return;
	}
	if(currentRoute.path.includes('dean') && toHrmsWhiteList.includes(to.path)){
		// console.log('to.path',to);
		next({
			path: `${to.path}/dean`,
			query: to.query,
		});
		return;
	}
	// console.log(hasRoutes);
	// console.log('next');
	next()
})



router.afterEach(() => {
	NProgress.done()
})
