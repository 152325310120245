import Vue from 'vue';

import Menu from '@/components/menu';
Vue.component('Menu',Menu);

import CommonTable from '@/common/module/table';
Vue.component('CommonTable',CommonTable);

import BaseInfo from '@/components/table/BaseInfo.vue';
import ColumnTable from '@/components/table/ColumnTable.vue';
import Report from '@/components/table/Report.vue';
import RowTable from '@/components/table/RowTable.vue';
import Upload from '@/components/table/Upload.vue';
import RowForm from '@/components/form/RowForm.vue';
import Search from '@/components/search';
import FlowHeader from '@/components/flowHeader';
import MySelectGroup from '@/common/my-element/mySelectGroup';

Vue.component('BaseInfo',BaseInfo);
Vue.component('ColumnTable',ColumnTable);
Vue.component('Report',Report);
Vue.component('RowTable',RowTable);
Vue.component('Upload',Upload);
Vue.component('RowForm',RowForm);
Vue.component('Search',Search);
Vue.component('FlowHeader',FlowHeader);
Vue.component('MySelectGroup',MySelectGroup);

import MyCascader from '@/common/my-element/myCascader';
Vue.component('MyCascader',MyCascader);
import FileViewer from '@/common/fileViewer';
Vue.component('FileViewer',FileViewer);