<template>
    <el-form ref="form" :model="form" label-width="80px" label-position="top" :rules="rules" size="small">
        <el-row :gutter="30">
            <el-col :span="8">
                <el-form-item label="干部人员" prop="name">
                    <el-input  :disabled="true" v-model="form.name" clearable></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="调职类型" prop="type">
                    <el-select v-model="form.type" clearable>
                        <el-option v-for="item in changeStatusList" :key="item.value" :value="item.value" :label="item.label"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="调职日期" prop="adjustTime">
                    <el-date-picker
                        v-model="form.adjustTime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        clearable>
                    </el-date-picker>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="30">
            <el-col :span="12">
                <el-form-item :label="$replaceText('原任部门')" prop="originalOrgId">
					<!-- <el-input v-model="positionText" disabled></el-input> -->
                    <MyCascader v-model="form.originalOrgId" :options="orgList" size="small" :props="cascaderProps" disabled clearable></MyCascader>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="原任职务" prop="originalPositionIds">
                    <MySelectGroup v-model="form.originalPositionIds" :options="jobList" disabled placeholder=""> </MySelectGroup>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row :gutter="30">
            <el-col :span="12">
                <el-form-item :label="$replaceText('现任部门')" prop="currentOrgId">
                    <MyCascader v-model="form.currentOrgId" :options="orgList" size="small"  :props="cascaderProps" clearable></MyCascader>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="现任职务" prop="currentPositionIds">
                    <MySelectGroup v-model="form.currentPositionIds" :options="jobList" placeholder=""> </MySelectGroup>
                </el-form-item>
            </el-col>
        </el-row>
        <el-form-item label="调动原因" prop="adjustReason">
            <el-input :rows="3" resize="none" type="textarea" v-model="form.adjustReason" clearable></el-input>
        </el-form-item>
        <el-form-item label="呈报单位" prop="reportUnit">
            <el-input :rows="3" resize="none" type="textarea" v-model="form.reportUnit" clearable></el-input>
        </el-form-item>
        <el-form-item label="审批机关意见" prop="approvalAuthorityOpinion">
            <el-input :rows="3" resize="none" type="textarea" v-model="form.approvalAuthorityOpinion" clearable></el-input>
        </el-form-item>
        <el-form-item label="行政机关任免意见" prop="positionChangeOpinion">
            <el-input :rows="3" resize="none" type="textarea" v-model="form.positionChangeOpinion" clearable></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="notes">
            <el-input :rows="3" resize="none" type="textarea" v-model="form.notes" clearable></el-input>
        </el-form-item>

        
    </el-form>
</template>

<script>
import { getJobList , getOrgList } from '@/api/hrAPI.js';
import { getIdListInTree, getCourtByOrgId } from '@/utils/handle.js';

export default {
    props: {
        form: {
            type: Object,
            default(){
                return {};
            }
        },
    },
    data(){
        return {
            jobList: [],
            orgList: [],
            rules: {
                type: [
                    { required: true, message: '请选择调职类型', trigger: 'change' },
                ],
                currentOrgId: [
                    { required: true, message:`请选择${this.$replaceText('现任部门')}`, trigger: 'change' },
                ],
                currentPositionIds: [
                    { required: true, message: '请选择现任职务', trigger: 'change' },
                ]

            },
            orgIdList: [],
			positionText: '',
            currentOrgIdList: [],
            cascaderProps: {
                label: 'name',
                value: 'id',
                checkStrictly: true
            },
        }
    },
    computed: {
        changeStatusList(){
            return this.EnumList.changeStatusList.filter((item,index) => index < 2);
        }
    },
    created(){
        getJobList().then(res => {
            this.jobList = this.initJobOptions(res);
        })
        getOrgList().then(orgs => {
			const { originalOrgId } = this.form
			const res = {courts: [], pid: ''}
			getCourtByOrgId(orgs, originalOrgId, res)
			// this.positionText = res.courts.map(court => court.name).join('/')
			const court = res.courts.find(court => court.pid === res.pid || court.id === res.pid)

			if(court) {
				court.children = court.children.filter(child => child.type === 'DEPT')
			} 

			this.orgList = court ? [court] : []
        })
    },
    methods: {
        submit(){
            return new Promise((resolve,reject) => {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        resolve();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                })

            })
        },
        resetForm() {
            this.$refs.form.resetFields();
        },
        initJobOptions(res){
            const list = [];
            res.forEach(job => {
                let target = list.find(item => item.label === job.typeName);
                if(!target){
                    target = {
                        label: job.typeName,
                        options: [],
                    }
                    list.push(target);
                }
                target.options.push({
                    label: job.name,
                    value: job.id
                })
                
            })
            return list;
        }
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
/deep/ .el-form-item__label{
    line-height: 1;
}
/deep/ .el-select{
    width: 100%;
}
/deep/ .el-date-editor{
    width: 100%;
}
/deep/ .el-cascader{
    width: 100%;
}
</style>