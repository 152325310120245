import Vue from 'vue';
import store from "@/store";
import router from "@/router";

import Tooltip from '@/common/tooltip';
const TooltipContainer = Vue.extend(Tooltip);
let instance;
        
            
instance = new TooltipContainer({        //  实例才能挂载($mount)
    
}); 
instance.$mount();
document.body.appendChild(instance.$el);



// 注册一个全局自定义指令 `v-tooltip`--------------提示(title)
Vue.directive('tooltip', {
    // 当被绑定的元素插入到 DOM 中时……
    bind: function (el, binding, vnode, oldVnode) {
        // loading    
        // 聚焦元素
        // console.log(el);    //  元素
        // console.log(binding);   //  对象
        // console.log(vnode);     //  虚拟节点
        // console.log(oldVnode);  //  上一个虚拟节点
        // console.log(render);

        const content = binding.value;
        el.addEventListener('mouseenter',event => {
            let position = el.getBoundingClientRect();
            instance.left = position.left;
            instance.top = position.bottom;
            instance.visible = true;
            instance.text = content
        })

        el.addEventListener('mouseleave',event => {
            instance.visible = false;
        })
        
    }
})



import { Loading } from 'element-ui';
Vue.directive('myLoading',{
    bind: function(el){
        el.loading = null;
    },
    // 当被绑定的元素插入到 DOM 中时……
    update: function (el, binding, vnode, oldVnode) {
        // loading    
        // 聚焦元素
        // console.log(el);    //  元素
        // console.log(binding);   //  对象
        // console.log(vnode);     //  虚拟节点
        // console.log(oldVnode);  //  上一个虚拟节点
        // console.log(render);
        const flag = binding.value;
        if(flag){
            el.loading = Loading.service({ target: el });
        }else{
            el.loading && el.loading.close();
        }
    }
})

// 注册一个全局自定义指令 `v-hasPermit`
Vue.directive('hasPermit', {
    // 当被绑定的元素插入到 DOM 中时……
    inserted: function (el, binding, vnode, oldVnode) {
        const permitList = store.state.common.permitList
        // debugger
        // loading    
        // 聚焦元素
        // console.log(el);    //  元素
        // console.log(binding);   //  对象
        // console.log(vnode);     //  虚拟节点
        // console.log(oldVnode);  //  上一个虚拟节点
        
        let url = el.getAttribute('url');
        
        // console.log(url,el);
        if(url === '**' || url === '' || !url){
            return;
        }
        if (url) {
            let urlList = url.split(',');
            
            let flag = permitList.find(permit => {
                if (permit.indexOf("**") != -1) {
                    permit = permit.replace("**", ".*");
                } else {
                    if (permit.indexOf("*") != -1) {
                        permit = permit.replace("*", "[a-zA-Z0-9_\\-]*");
                    }
                }
                return urlList.find(url => new RegExp(`^${permit}$`).test(url));
            })
            
            if (!flag) {      //  未拥有权限（url）
                el.parentNode.removeChild(el);
            }
        } else {
            el.parentNode.removeChild(el);
        }
    },

})

export function getLibName() {
	let addPath = '';
	if (router.currentRoute.path.endsWith('/cadre')) {
		addPath = '/cadre';
	}
	if (router.currentRoute.path.endsWith('/dean')) {
		addPath = '/dean';
	}
	if (/system\/.*/.test(router.currentRoute.path)) {        //  已system(系统设置,没有route区分,由选择的时候设置addPath)
		addPath = router.currentRoute.meta.addPath;
	}

	return addPath
}


function checkPermission(el, binding) {
	const addPath = getLibName()
	if (binding.value && binding.value instanceof Array) {
		const  permissions = binding.value.map(v => addPath ? v.replace('/hrms', `/hrms${addPath}`) : v)
		const userPermissions = store.getters?.permissions;
		const hasPermission = userPermissions.some(path => {
			if (path.indexOf("**") != -1) {
				path = path.replace("**", ".*");
			} else {
				if (path.indexOf("*") != -1) {
					path = path.replace("*", "[a-zA-Z0-9_\\-]*");
				}
			}
			if (path === '/hrms/') return false
			return permissions.some(p => new RegExp(path).test(p))
		});

		if (!hasPermission) {
			el.parentNode?.removeChild(el);
		}
	} else {
		throw new Error("need API! like v-permission='['/user/base/base-info']'");
	}
}

Vue.directive('permission', {
	inserted(el, binding) {
		checkPermission(el, binding);
	},
	update(el, binding) {
		checkPermission(el, binding);
	}
})


