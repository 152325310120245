import service from '@/utils/service.js';

//  图片预处理（自定义参数）
export const filePre = data => {
    return service.post('/basic/pre-process/execute-params', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
        responseType: 'blob'
    })
}
//  更新预处理图片
export const updatePreFile = data => {
    return service.post('/caseinfo/classify/update-pre-img', data, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        
    })
}
//  上传照片，上传成功返回文件地址和文件id
export const uploadAvatar = data => {
    return service.post('/hrms/file/upload/avatar', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
        
    })
}

//  上传文件，上传成功返回文件地址和文件id
export const uploadFile = data => {
    return service.post('/hrms/file/upload/attachment', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
        
    })
}

//  上传文件，上传成功返回文件地址和文件id
export const downloadZIP = params => {
    return service.get('/hrms/user/download-zip', {
        params,
        headers: { 'Content-Type': 'multipart/form-data' },
        
    })
}

//  表单信息提取
export function extract(data){
    return service.post('/hrms/nlp/extract',data,{
        headers: {'Content-Type': 'multipart/form-data'}
    })
}