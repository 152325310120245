<template>
    <el-cascader size="small" :value="values" @input="handleInput" :options="options" :props="props" @change="handleChange"
        :show-all-levels="true" :disabled="disabled" clearable>
    </el-cascader>
</template>

<script>
import { getIdListInTree } from '@/utils/handle.js';

export default {
    props: ['size', 'options', 'props', 'show-all-levels', 'value', 'disabled'],
    data () {
        return {
            values: [],
        }
    },
    computed: {
        changeData(){
            return {
                value: this.value,
                options: this.options,
            }
        }
    },
    watch: {
        changeData: {
            handler(val){
                const values = getIdListInTree(this.options, this.value);
                this.values = values;
                if(!values.length){
                    if(this.options.length && this.value){
                        this.$emit('input','');
                    }
                }
            },
            immediate: true
        },
        // value: {
        //     handler (val) {
        //         console.log('watch',val);
        //         const values = getIdListInTree(this.options, val);
        //         console.log(JSON.parse(JSON.stringify(values)));
        //         this.values = values;

        //         // if(!values.length){
        //         //     this.$emit('input','');
        //         // }
        //     },
        //     immediate: true
        // },
        // options: {
        //     handler (val) {
        //         const values = getIdListInTree(this.options, this.value);
        //         this.values = values;
        //     },
        //     immediate: true
        // }
    },
    methods: {
        handleInput (list) {
            // el-cascader有bug；
            // 当选择了值之后，会改变内部的checkValues，而不是用外部props的value，不完全符合v-model
            if (list && list.length) {
                this.$emit('input', list[list.length - 1]);
                // this.$emit('change', list[list.length - 1]);
            } else {
                this.$emit('input', '');
                // this.$emit('change', '');
            }
        },
        handleChange (list) {
            if (list && list.length) {
                this.$emit('change', list[list.length - 1]);
            } else {
                this.$emit('change', '');
            }
        }
    },
    mounted () {

    },
}
</script>

<style lang="less" scoped></style>