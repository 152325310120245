<template>
    <div class="table-form" :class="{'detail': isDetail}">
        <div class="title">
            基本信息
            <slot name="btn"></slot>
        </div>
        <table border="1">
            <tr>
                <td>
                    <span>姓名</span>
                    <span v-if="!isDetail" class="require"></span>
                </td>
                <td>
                    <div v-if="isDetail">{{form.name}}</div>
                    <el-input v-else v-model="form.name" :readonly="isDetail" :disabled="disabledKeys.includes('name')"></el-input>
                </td>
                <td class="require">性别</td>
                <td>
                    <div v-if="!isDetail">
                        <el-radio-group v-model="form.sex">
                            <el-radio label="MALE">男</el-radio>
                            <el-radio label="FEMALE">女</el-radio>
                        </el-radio-group>
                    </div>
                    <div v-else>
                        {{form.sex === 'MALE' ? '男' : form.sex === 'FEMALE' ? '女' : ''}}
                    </div>
                </td>
                <td rowspan="3" class="img-td" style="padding:0;">
                    <div class="user-image">
                        <div v-if="!isDetail && !form.avatar" class="upload" @click="upload">
                            <i class="iconfont icon-tianjia"></i>
                            <span>上传头像</span>
                        </div>
                        <div v-else class="img-container">
                            <img v-if="form.avatar" :src="form.avatar" alt="">
                            <img v-else src="@/assets/user-default.png" alt="">
                            <i v-if="!isDetail" class="del iconfont icon-shanchu" @click="delFile"></i>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <span>出生年月</span>
                    <span v-if="!isDetail" class="require"></span>
                </td>
                <td>
                    <el-date-picker v-model="form.birthday" type="month" :readonly="isDetail" @change="birthdayChange"
                        value-format="yyyy-MM" format="yyyy.MM" :picker-options="pickerOptions"></el-date-picker>
                </td>
                <td>年龄</td>
                <td class="disabled">
                    <el-input v-model="form.age" :disabled="true"></el-input>
                </td>
            </tr>
            <tr>
                <td>
                    <span>身份证号</span>
                    <span v-if="!isDetail" class="require"></span>
                </td>
                <td style="position:relative;" :class="{'disabled': disabledKeys.includes('idCard')}">
                    <el-input v-model="form.idCard" :readonly="isDetail"  
                        :disabled="disabledKeys.includes('idCard')"
                        style="padding-right:24px;box-sizing:border-box;">
                    </el-input>
                    <button v-if="(form.idCard && !isDetail) && !disabledKeys.includes('idCard')" class="btn" @click="check">查重</button>
                </td>
                <td>民族</td>
                <td>
                    <div v-if="isDetail">{{form.nation}}</div>
                    <el-select v-else v-model="form.nation">
                        <el-option v-for="item in nationList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </td>
            </tr>
            <tr>
                <td>籍贯</td>
                <td class="table-td">
                    <div v-if="isDetail">{{form.nativePlace}}</div>
                    <el-input v-else v-model="form.nativePlace" :readonly="isDetail"></el-input>
                </td>
                <td>出生地</td>
                <td colspan="2">
                    <div v-if="isDetail">{{form.birthPlace}}</div>
                    <el-input v-else v-model="form.birthPlace" :readonly="isDetail"></el-input>
                </td>
            </tr>
            <tr>
                <td>入党时间</td>
                <td><el-date-picker v-model="form.joinPartyTime" type="month" :readonly="isDetail"
                        :picker-options="pickerOptions" value-format="yyyy-MM" format="yyyy.MM"></el-date-picker></td>
                <td>
                    <span>参加工作时间</span>
                    <span v-if="!isDetail" class="require"></span>
                </td>
                <td colspan="2"><el-date-picker v-model="form.joinWorkTime" type="month" :readonly="isDetail"
                        @change="workTimeChange" value-format="yyyy-MM" format="yyyy.MM"
                        :picker-options="pickerOptions"></el-date-picker></td>
            </tr>
            <tr>
                <td>健康状况</td>
                <td>
                    <div v-if="isDetail">{{form.healthStatus}}</div>
                    <el-input v-else v-model="form.healthStatus" :readonly="isDetail"></el-input>
                </td>
                <td>工作年限</td>
                <td colspan="2" class="disabled">
                    <div v-if="isDetail">{{form.workYear}}</div>
                    <el-input v-else v-model="form.workYear" :disabled="true"></el-input>
                </td>
            </tr>
            <tr>
                <td>专业技能职务</td>
                <td>
                    <div v-if="isDetail">{{form.specializedSkills}}</div>
                    <el-input v-else v-model="form.specializedSkills" :readonly="isDetail"></el-input>
                </td>
                <td>熟悉专业/有何专长</td>
                <td colspan="2">
                    <div v-if="isDetail">{{form.majorStrength}}</div>
                    <el-input v-else v-model="form.majorStrength" :readonly="isDetail"></el-input>
                </td>
            </tr>
        </table>
        <input ref="inputFile" type="file" v-show="false" @change="inputChange">
    </div>
</template>

<script>
import { uploadAvatar } from '@/api/fileAPI.js';
import { checkIDcard } from '@/api/hrAPI.js';
import { nation } from './data';

export default {
    props: {
        isDetail: {
            type: Boolean,
            default: true
        },
        form: {
            type: Object,
            default () {
                return {};
            }
        },
        disabledKeys: {
            type: Array,
            default(){
                return [];
            }
        }
    },
    data () {
        return {
            pickerOptions: {
                disabledDate (time) {
                    return time.getTime() > Date.now();
                },
            },
            nationList: nation
        };
    },
    created () {
        if (this.form.birthday) {
            this.birthdayChange(this.form.birthday);
        }
        if (this.form.joinWorkTime) {
            this.workTimeChange(this.form.joinWorkTime);
        }
        if (!this.form.avatar) {
            this.$set(this.form, 'avatar', '');
        }
    },
    methods: {
        birthdayChange (val) {
            this.$set(this.form, 'age', this.calculateAge(val));
        },
        workTimeChange (val) {
            this.$set(this.form, 'workYear', this.calculateAge(val));
        },
        calculateAge (date) {
            if (date) {
                // 入职时间
                var birthDate = new Date(date);
                // 当前时间
                var today = new Date();
                var age = today.getFullYear() - birthDate.getFullYear();
                var m = today.getMonth() - birthDate.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                    age--;
                }

                if (age <= 1) {
                    return 1;
                } else {
                    return age;
                }
            } else {
                return '';
            }
        },
        upload () {
            this.$refs.inputFile.click();
        },
        inputChange () {
            const files = this.$refs.inputFile.files;

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                uploadAvatar({ file }).then(res => {
                    // this.form.avatar = res.filePath;
					this.$set(this.form, 'avatar',  res.filePath)
                }).finally(() => {
                    this.$refs.inputFile.value = '';
                })
            }
        },
        delFile () {
            this.form.avatar = '';
        },
        check () {
            checkIDcard({ idCard: this.form.idCard }).then(res => {
                this.$message.success(`您录入的【${this.form.name}（${this.form.idCard}）】不是组织结构内成员，可继续录入`)
            })
        },
    },
    mounted () { }
};
</script>
<style lang="less" scoped>
td:nth-child(1),
td:nth-child(3) {
    width: 15%;
    background-color: #F0F2F5;
}

td:nth-child(2) {
    width: 35%;
}

.table-form table /deep/ tr {
    min-height: 48px;
}

.img-td{
    width: 105px;
    height: 145px;
    .user-image {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .img-container {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            display: flex;

            img {
                max-width: 100%;
                max-height: 100%;
            }

            .del {
                position: absolute;
                right: 5px;
                top: 5px;
                color: #FF2525;
                display: none;
            }
        }

        .img-container:hover {
            .del {
                cursor: pointer;
                display: inline-block;
            }
        }

        .upload {
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;

            i {
                margin-bottom: 10px;
            }
        }

    }
}



.btn {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    color: #3291F8;
    padding: 0;
}

</style>