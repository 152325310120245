<template>
    <div :class="{'isDetail': isDetail}" v-if="detailInfo" id="print">
        <!-- <BaseInfo :form="detailInfo" :isDetail="isDetail" :disabledKeys="disabledKeys">
            <template slot="btn" v-if="hasPrint">
                <i class="el-icon-printer iPrinter" v-print="printObj"></i>
            </template>
        </BaseInfo> -->
        <OtherBaseInfo :form="detailInfo" :isDetail="isDetail" :disabledKeys="disabledKeys">

        </OtherBaseInfo>

        <ColumnTable class="mt15" :title="educationTitle" :headers="educationHeaders" :tableData="educationInfoList"
            :isDetail="isDetail" :hasOperate="!isDetail">
            <template slot="startTime" slot-scope="{form}">
                <el-date-picker :disabled="isDetail" v-model="form.startTime" type="month" value-format="yyyy-MM"
                    :pickerOptions="{
                        disabledDate(time) {
                            return startTimeDisabledDate(time, form.endTime)
                        }
                    }">
                </el-date-picker>
            </template>
            <template slot="endTime" slot-scope="{form}">
                <el-date-picker :disabled="isDetail" v-model="form.endTime" type="month" value-format="yyyy-MM"
                    format="yyyy.MM" :pickerOptions="{
                        disabledDate(time) {
                            return endTimeDisabledDate(time, form.startTime)
                        }
                    }">
                </el-date-picker>
            </template>
        </ColumnTable>
        <RowTable class="mt15" :title="jobTitle" :headers="dutiesHeaders" :form="positionInfo" :isDetail="isDetail" :disabledKeys="disabledKeys">
            <template slot="org">
				<!-- <el-input v-if="positionText" v-model="positionText" disabled></el-input> -->
                <MyCascader :disabled="isDetail || disabledKeys.includes('orgId')" v-model="positionInfo.orgId" :options="filteredOrgList" :props="{
                    checkStrictly: true,
                    label: 'name',
                    value: 'id'
                }"></MyCascader> 
            </template>
            <template slot="currentPositionIds">
				<div v-if="isDetail || disabledKeys.includes('currentPositionIds')">{{positionLabels[0]}}</div>
				<MySelectGroup v-else v-model="positionInfo.currentPositionIds" :options="dutiesHeaders[1].optionList"
					:disabled="isDetail || disabledKeys.includes('currentPositionIds')">
				</MySelectGroup>
			</template>
			<template slot="proposedPositionIds" v-if="dutiesHeaders[2]">
				<div v-if="isDetail">{{positionLabels[1]}}</div>
				<MySelectGroup v-else v-model="positionInfo.proposedPositionIds" :options="dutiesHeaders[2].optionList"
					:disabled="isDetail"></MySelectGroup>
			</template>
			<template slot="retirementPositionIds" v-if="dutiesHeaders[3]">
                <MySelectGroup v-model="positionInfo.retirementPositionIds" :options="dutiesHeaders[3].optionList" :disabled="isDetail"></MySelectGroup>
            </template>
        </RowTable>
        <ColumnTable class="mt15" :title="easyTitle" :headers="resumeHeaders" :tableData="biographicalNotesInfoList"
            :isDetail="isDetail" :hasOperate="!isDetail">
            <template slot="startTime" slot-scope="{form}">
                <el-date-picker :disabled="isDetail" v-model="form.startTime" type="month" value-format="yyyy-MM"
                    format="yyyy.MM" :pickerOptions="{
                        disabledDate(time) {
                            return startTimeDisabledDate(time, form.endTime)
                        }
                    }">
                </el-date-picker>
            </template>
            <template slot="endTime" slot-scope="{form}">
                <el-date-picker :disabled="isDetail" v-model="form.endTime" type="month" value-format="yyyy-MM"
                    format="yyyy.MM" :pickerOptions="{
                        disabledDate(time) {
                            return endTimeDisabledDate(time, form.startTime)
                        }
                    }">
                </el-date-picker>
            </template>
        </ColumnTable>

        <RowTable v-if="currentLib" class="mt15" title="奖惩信息" :headers="kaohe1" :form="assessmentSituationsInfo" :isDetail="isDetail"></RowTable>
        <RowTable v-if="currentLib" class="mt15" title="年度考核信息" :headers="kaohe2" :form="assessmentSituationsInfo" :isDetail="isDetail"></RowTable>

        <RowTable v-if="!currentLib" class="mt15" title="考核情况" :headers="kaoheHeaders" :form="assessmentSituationsInfo" :isDetail="isDetail"></RowTable>

        <ColumnTable class="mt15" :title="$replaceText('家庭主要成员及重要社会关系信息')" :headers="familyHeaders" :tableData="userRelationInfoList"
            :isDetail="isDetail" :hasOperate="!isDetail">
            <template slot="age" slot-scope="{form}">
                <el-input-number :disabled="isDetail" :value="form.age || undefined" @input="val => form.age = val ? val : undefined" clearable></el-input-number>
            </template>
        </ColumnTable>
        <Report class="mt15" :form="presentationInfo" :isDetail="isDetail" v-if="currentLib"></Report>
		<!-- <Upload class="mt15" :fileList="fileInfoList" :isDetail="isDetail" :cadreFileList="cadreFileList"></Upload> -->
		<UploadSort class="mt15" :fileList="fileInfoList" :isDetail="isDetail" :cadreFileList="cadreFileList"></UploadSort>

    </div>
</template>

<script>
import BaseInfo from '@/components/table/BaseInfo.vue';
import RowTable from '@/components/table/RowTable.vue';
import Report from '@/components/table/Report.vue';
import Upload from '@/components/table/Upload.vue';
import UploadSort from '@/components/table/UploadSort.vue';
import OtherBaseInfo from '@/components/table/OtherBaseInfo.vue';


import { education, duties, otherDuties , resume, kaohe, family , kaohe1 , kaohe2 } from '@/components/table/data.js';
import { getJobList, getOrgList } from '@/api/hrAPI.js';
import { getCourtByOrgId, getCourtByCourtId } from '@/utils/handle.js';

export default {
    props: {
        detailInfo: {
            type: Object,
            default () {
                return {};
            }
        },
        isDetail: {
            type: Boolean,
            default: true
        },
        cadreFileList:{
            type:Array,
            default(){
                return []
            }
        },
        disabledKeys: {
            type: Array,
            default(){
                return [];
            }
        },
        hasPrint: {         //  基本信息是否有打印按钮
            type: Boolean,
            default: true,
        },
		editable: {
			type: Boolean,
            default: false,
		},
    },
    components: {
        BaseInfo,
        RowTable,
        Report,
        Upload,
        UploadSort,
        OtherBaseInfo
    },
    data () {
        return {
            printObj: {
                id: 'print',
                popTitle: '', // 打印配置页上方标题
                extraCss: '', // 打印可引入外部的一个 css 文件
            },
            educationHeaders: education,
            dutiesHeaders: duties,
            resumeHeaders: resume,
            kaoheHeaders: kaohe,
            familyHeaders: family,
            kaohe1: kaohe1,
            kaohe2: kaohe2,
            jobTitle: '',
            easyTitle: '',
			educationTitle: '',
            jobList: [],
            orgList: [],
			filteredOrgList: [],
			positionLabels: ['', '', ''],
			positionOptions: [],
            startTimeDisabledDate: (time, endTime) => {
                if(endTime){
                    return time.getTime() > Date.now() || (new Date(endTime).getTime() - 8 * 3600 * 1000) < time.getTime();
                }else{
                    return time.getTime() > Date.now();
                }
            },
            endTimeDisabledDate: (time, startTime) => {
                //  startTime为月份,月份通过new Date()计算出来为当天08:00:00，time计算出来为00:00:00,所以计算每月1号的时候，time是小于startTime的，所以不能被选中
                //  天坑--只有每月1号会出这种bug
                // if(time.getFullYear() === new Date(startTime).getFullYear()){
                //     console.log(time,startTime);
                //     console.log(time.getTime() > Date.now() , new Date(startTime).getTime() > time.getTime());
                //     // console.log(time.getTime() > Date.now() || new Date(startTime).getTime() > time.getTime());
                // }
                
                if(startTime){
                    return time.getTime() > Date.now() || (new Date(startTime).getTime() - 8 * 3600 * 1000) > time.getTime();
                }else{
                    return time.getTime() > Date.now();
                }
            },
        }
    },
    computed: {
        educationInfoList () {
            return this.detailInfo.educationInfoList;
        },
        positionInfo () {
            return this.detailInfo.positionInfo;
        },
        biographicalNotesInfoList () {
            return this.detailInfo.biographicalNotesInfoList;
        },
        assessmentSituationsInfo () {     //  详情访问undefined报错
            return this.detailInfo.assessmentSituationsInfo;
        },
        userRelationInfoList () {
            return this.detailInfo.userRelationInfoList;
        },
        presentationInfo () {
            return this.detailInfo.presentationInfo;
        },
        fileInfoList () {
            return this.detailInfo.fileInfoList;
        },
        currentLib(){       //  当前库
            return this.$getCurrentLib();
        }
    },
	watch:{
		'positionInfo.orgId': {
			handler(val) {
				this.filterOrgList(val)
			},
			immediate: true
		},
		'positionInfo.currentPositionIds': {
			handler(val) {
				if(this.detailInfo.EDIT_OPERATION) {
					this.positionLabels = [this.getPositionLabels(this.positionOptions,val), '', '']
				}
			},
		}
	},
    async created () {
        this.initTemplate();        //  初始化表格模板
        const res = await getJobList();
        const options = this.positionOptions = this.initJobOptions(res);
		const {currentPositionIds, proposedPositionIds, retirementPositionIds} = this.positionInfo
		const positions = [currentPositionIds, proposedPositionIds, retirementPositionIds]
        for (let i = 1; i < this.dutiesHeaders.length; i++) {
            const item = this.dutiesHeaders[i];
            item.type = 'select';
            item.optionList = options;
			if(positions[i-1]) {
				this.positionLabels[i-1] = this.getPositionLabels(options, positions[i-1])
			}
        }

        const orgs = await getOrgList()
		this.orgList = orgs
		const { orgId } = this.positionInfo
		this.filterOrgList(orgId)
    },
    methods: {
		getPositionLabels(options, optIds) {
			optIds = optIds.split(',')
			const optInIds = []
			if(optIds) {
				const opts = options.map(opt => opt.options)
				const list = []
				opts.forEach(opts => list.push(...opts))

				optIds.forEach(id => {
					const target = list.find(opt => opt.value === id)
					if(target) {
						optInIds.push(target)
					}
				})
			}

			return optInIds.map(opt => opt.label).join('；')
		},
		filterOrgList(orgId) {
			if(!orgId) {
				const c = {court: null}
				const courtId = this.$store.getters.userInfo.court.id
				getCourtByCourtId(this.orgList, courtId, c)

				if(!c.court) return this.filteredOrgList = []
				if(c.court.children) {
					c.court.children = c.court.children.filter(child => child.type === 'DEPT')
				}
			
				this.filteredOrgList =  c.court ? [c.court] : []
				return 
			}

			const res = {courts: [], pid: ''}

			getCourtByOrgId(this.orgList, orgId, res)
			// this.positionText = res.courts.map(court => court.name).join('/') 
			const court = res.courts.find(court => court.pid === res.pid || court.id === res.pid)
			this.filteredOrgList  =  court ? [court] : []
		},
        initTemplate(){
            if(this.currentLib){        //  当前库为2,3
                this.dutiesHeaders = otherDuties;
                this.jobTitle = '职务职级信息';
                this.easyTitle = '简历信息';
				this.educationTitle = '学历学位信息'
				// this.
            }else{
                this.easyTitle = '简历';
                this.jobTitle = '职务信息';
				this.educationTitle = '教育信息'
            }
        },
        initJobOptions(res){
            const list = [];
            res.forEach(job => {
                let target = list.find(item => item.label === job.typeName);
                if(!target){
                    target = {
                        label: job.typeName,
                        options: [],
                    }
                    list.push(target);
                }
                target.options.push({
                    label: job.name,
                    value: job.id
                })
                
            })
            return list;
        }
    },
}
</script>

<style lang="less" scoped>
.isDetail{
    /deep/ .el-input__prefix,/deep/ .el-input__suffix{
        display: none;
    }
    /deep/ input{
        padding: 0 !important;
    }
    /deep/ input::placeholder{
        // display: none !important;
        // color: #000 !important;
        opacity: 0;
    }
}
.footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
}
.tablew /deep/ .table-form{
    width: 100% !important;
}
.iPrinter{
    margin-right: 20px;
}
/deep/ .el-input-number{
    .el-input__inner{
        padding-left: 15px;
        padding-right: 30px;
    }
    .el-input-number__decrease,.el-input-number__increase{
        display: none;
    }
}
</style>