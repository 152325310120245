<template>
    <div class="table-form" :class="{'detail': isDetail}">
        <div class="title">
            <span>{{ title }}</span>
           	<button v-if="hasOperate" class="el-icon-circle-plus-outline" @click="add"></button>
        </div>
        <table class="table-form" border="1">
            <tr>
                <th :class="{'require': item.require}" v-for="(item, index) in headers" :key="index" :style="{ width: item.width }">{{ item.label }}</th>
                <th v-if="hasOperate">操作</th>
            </tr>
            <tr v-for="(form, index) in tableData" :key="index">
                <td v-for="(header, headerIndex) in headers" :key="header.value">
                    <slot v-if="header.slot" :name="header.slot" :form="form"></slot>
                    <div v-else-if="header.type === 'select'">
                        <el-select size="small" v-model="form[header.value]" :disabled="isDetail" clearable>
                            <el-option v-for="item in headers[headerIndex].optionList" :key="item.value" :label="item.label"
                                :value="item.value"></el-option>
                        </el-select>
                    </div>
                    <div v-else>
                        <div v-if="isDetail"> {{form[header.value]}} </div>
                        <el-input v-else v-model="form[header.value]" :readonly="isDetail" clearable></el-input>
                    </div>
                </td>
                <td width="80" class="operate" v-if="hasOperate">
					<button @click="up(index)">
						<img v-if="index > 0" src="@/assets/up-black.png" />
						<img v-else src="@/assets/up-grey.png" />
					</button>
            		<button @click="down(index)">
						<img v-if="index < tableData.length - 1" src="@/assets/down-black.png" />
						<img v-else src="@/assets/down-grey.png" />
					</button>
                    <el-dropdown>
                        <span class="el-icon-more"> </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item @click.native="copy(form)">复制</el-dropdown-item>
                            <el-dropdown-item @click.native="del(index)">删除</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        isDetail: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: ''
        },
        headers: {
            type: Array,
            default () {
                return [];
            }
        },

        tableData: {
            type: Array,
            default () {
                return [];
            }
        },
        hasOperate: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {

        };
    },
    created(){
        if (this.tableData.length <1&&this.tableData=='') {
            this.add()
        }
    },
    updated() {
        if (this.tableData.length <1&&this.tableData=='') {
            this.add()
        }
    },
    methods: {
        add () {
            this.tableData.push({})
        },
		swap(index) {
			const prev = this.tableData.splice(index - 1, 1)[0]
			this.tableData.splice(index, 0, prev)
		},
		up(index) {
			console.log('UP-----', index)
			if(index > 0) {
				this.swap(index)
			}
		},
		down(index) {
			console.log('Down-----', index)
			if(index < this.tableData.length - 1) {
				this.swap(index + 1)
			}
		},
        copy (row) {
            this.tableData.push(JSON.parse(JSON.stringify(row)))
        },
        del (index) {
            this.tableData.splice(index, 1);
        },
    },
};
</script>

<style lang="less" scoped>
th {
    background-color: #F0F2F5;
    text-align: left;
    font-weight: normal;
}

.operate {
    box-sizing: border-box;
	display: flex;
	height: 48px;
	width: 100%;
	border: none;
	align-items: center;
	justify-content: center;

	>* {
		width: 14px;
		height: 14px;
		margin-left: 5px;
		border: none;

		&:first {
			margin: 0;
		}
	}



    /deep/ .el-dropdown {
        cursor: pointer;
        display: flex;
        justify-content: center;

    }
}

.title {
	.btns {
		display: flex;

		button {
        // border: 1px solid #101010;
        // border-radius: 4px;
        	font-size: 16px;
			margin-left: 2px;
    	}
	}
}</style>