import axios from 'axios'
import store from '@/store';
import router from '@/router';
import { Message } from 'element-ui';
import Vue from 'vue';

const service = axios.create({
    baseURL: '/api_v2',
    timeout: 600000,
})

const checkNull= [
	'qualification',
	'educational_system',
	'educationalSystem',
	'degree',
	'major'
]

function setParams(data){       //  过滤掉多余的参数
    // console.log(data);
    if(data?.constructor === Object){
        Object.keys(data).forEach(key => {
            if(!data[key]){
				// delete data[key];
				if (checkNull.includes(key)) {
					delete data[key];
				}
            }else{
                setParams(data[key]);
            }
        })
    }else if(data?.constructor === Array){
        for (let i = 0; i < data.length; i++) {
            const item = data[i];
            setParams(item);
        }
    }
}

service.interceptors.request.use(config => {
    if(config.data){            //  删除多余的空参数
        const keys = Object.keys(config.data);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if(!config.data[key]){
                // delete config.data[key];
				if (checkNull.includes(key)) {
					delete config.data[key];
				}
            }else{
                setParams(config.data[key]);
            }
        }
    }
    
    // console.log(router.currentRoute.path);
	let addPath='';
    if(config.url.startsWith('/hrms')){         //  已hrms开头的url,才会走自动逻辑
        // console.log(router.currentRoute.path);
        if(!['/hrms/nlp/extract'].includes(config.url)){
            if(router.currentRoute.path.endsWith('/cadre')){
                addPath = '/cadre';
            }
            if(router.currentRoute.path.endsWith('/dean')){
                addPath = '/dean';
            }
            if(/system\/.*/.test(router.currentRoute.path)){        //  已system(系统设置,没有route区分,由选择的时候设置addPath)
                // console.log('system');
                addPath = router.currentRoute.meta.addPath;
            }
            if(addPath){
                config.url = config.url.replace('/hrms',`/hrms${addPath}`);
            }
        }
    }
    
	const notes = store.state.status.notes
	if(notes.path) {
		if (addPath) {
			notes.path = notes.path.replace('/hrms', `/hrms${addPath}`)
		}

		if (config.url.indexOf(notes.path) != -1) {
			if (!config.params) config.params = {}
			config.params._log_notes = notes.note
		}
	}
	
    const token = store.getters.token;
    if(token){
        config.headers['Authorization'] = token;
    }

    if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
        let form = new URLSearchParams();
        for (const key in config.data) {
            form.append(key, config.data[key]);
        }
        config.data = form;
        // return config;
    }
    if (config.headers['Content-Type'] === 'multipart/form-data') {
        let form = new FormData();
        for (const key in config.data) {
            form.append(key, config.data[key]);
        }
        config.data = form;
    }
    if(searchDownload(config)){     //  导出拦截
        return Promise.reject('请求被取消');
    }

    return config
}, (error) => {
    // console.log(error);
    Promise.reject(error)
})

let whiteList = [];

service.interceptors.response.use(response => {
    if (response.request.responseType === 'blob') {
        return response;
    }
    return response.data;
}, error => {
    if(error.response){
        const data = error.response.data;
        // console.log(data);
        const errorURL = error.response.config.url;
        let msgText = (data.message || data.detailMessage);
        // if(!Vue.prototype.$getCurrentLib()){            //  替换'部门'文本为'单位'
        //     msgText = msgText.replace('部门','单位');
        // }
        
        Message.error(msgText);
        // console.log(error.response);
        if(whiteList.includes(errorURL)){       //  特殊异常需要处理的接口
            // console.log(error.response);
            return Promise.reject(error.response.data);
        }
        if(data.code === 401){
            // getConfigs({ key: 'platformCenter' }).then(res => {
            //     // http://localhost:8080/login?WHRP_TOKEN=123&a=1&b=2&c=3(测试连接)

            //     // let reg = /(WHRP_TOKEN)=([^&]*)([&|\s])*/g;     //  替换参数(不然在无权限时，会把token带到应用中心)
            //     // const query = location.href.replace(reg,'').replace(/&$/,'');
            //     // const href = `${res}?redirectUrl=${encodeURIComponent(query)}`;
            //     // location.href = href;

            //     router.push({
            //         name: 'login'
            //     })

            // })
            // console.log('service-err');
            router.push({
                name: 'login'
            })
        }
        return Promise.reject(data)
    }

    return Promise.reject(error)
})

const downloadUrlList = [
    {
        label: '用户信息表',
        value: '/hrms/user/search-page',
    },
    {
        label: '各法院干部情况统计',
        value: '/hrms/statistic/query-user-situation',
    },
	{
		label: '干部性别统计',
		value: '/hrms/statistic/query-sex-in-court'
	},
    {
		label: '法官等级统计',
		value: '/hrms/statistic/query-rank-in-court'
    },
    {
		label: '干部职级统计',
		value: '/hrms/statistic/query-job-rank-in-court'
	},
    {
        label: '干部学历统计',
        value: '/hrms/statistic/query-qualification-range',
    },
    {
        label: '干部年龄统计',
        value: '/hrms/statistic/query-age-range',
    },
    {
        label: '干部工作年限统计',
        value: '/hrms/statistic/query-working-years',
    },
    {
        label: '新增干部统计',
        value: '/hrms/statistic/query-new-cadre',
    },
]

export function searchDownload({ url , params }){
    if(params && params.export === 'excel'){
        const item = downloadUrlList.find(item => {
            let suffix = item.value.substring(5);
            let reg = new RegExp(`.*${suffix}`);
            return reg.test(url)
        });
        if(item){
            params.fileName = item.label;
            let res = `/api_v2${url}?WHRP_TOKEN=${encodeURIComponent(store.getters.token)}&`;
            Object.keys(params).forEach(key => {
                res += `${key}=${params[key]}&`
            })
            const a = document.createElement('a');
            a.href = res;
            a.download = '';
            a.click();
            return true
        }
    }
    return false;
}

export default service

