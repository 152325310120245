import { getDict, getConfigs, logout, getUserInfo } from '@/api/commonAPI.js';
import { deepAssign } from '@/utils/handle.js';
import router from '@/router';
import { resetRouter } from '@/router';

const state = {
	dictMap: {
		'position_type': '',
		'position_type_cadre': '',
		'position_type_dean': '',
		'hrms_dir': '',
		'hrms_dir_cadre': '',
		'hrms_dir_dean': '',
	},
	configMap: {
		'platformCenter': '',
		'platformServiceUrl': '',
		'fileViewer': '',
		'cadreFileViewer': '',
		'hrmsConfig': {
			watermark: {
				enabled: false,
			}
		}
	},
	token: '',
	userInfo: null,
	permissions: [],
	currentHrmsDir: [],
}

const mutations = {
	SET_TOKEN(state, token) {
		state.token = token;
	},
	SET_USER_INFO(state, data) {
		state.userInfo = data;
	},
	SET_PERMISSIONS(state, permissions) {
		const list = [];
		permissions.reduce((arr, permit) => {         //  正则
			arr.push(...permit.split(','))
			return arr;
		}, list);
		state.permissions = list;
	},
}

const actions = {
	getUserInfo({ commit }) {
		return new Promise((resolve, reject) => {
			getUserInfo().then(res => {
				commit('SET_USER_INFO', res);
				commit('SET_PERMISSIONS', res.permissions);
				// console.log('SET_USER_INFO', res);
				resolve();
			}).catch(err => {
				reject(err);
			})
		})
	},
	getConfigMap({ state }) {
		return new Promise((resolve, reject) => {
			const configMap = state.configMap;
			const keys = Object.keys(configMap).join(',');
			getConfigs({ keys }).then(res => {
				const source = {};
				Object.keys(configMap).forEach((key, index) => {
					source[key] = res[index];
				})
				deepAssign(state.configMap, source);
				resolve();
			}).catch(err => {
				console.log(err);
				resolve();
			})
		})
	},
	getDictMap({ state }) {
		return new Promise((resolve, reject) => {
			const dictMap = state.dictMap;
			const keys = Object.keys(dictMap);
			getDict({ category: keys.join(',') }).then(res => {
				const source = {};
				keys.forEach((key, index) => {
					source[key] = res[index] || [];
				})
				state.dictMap = source;
				resolve();
			}).catch(err => {
				console.log(err);
				resolve();
			})
		})
	},
	logout({ state, commit }) {
		logout().then(() => {
			commit('SET_TOKEN', '');
			resetRouter([])
			router.push({ name: 'login' })
			// window.location = `${state.configMap.platformCenter}/login`;
		})
	},
	getHrmsDir({ state }) {
		const path = router.currentRouter.path;
		if (path.endsWith('/cadre')) {

		} else if (path.endsWith('/dean')) {

		} else {

		}

	}
}

// actions.getDictMap({state});

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
