<template>
    <div class="table-form" :class="{'detail': isDetail}">
        <div class="title">{{title}}</div>
        <table border="1">
            <tr v-for="header in headers" :key="header.value">
                <td :style="{width:header.width}" :class="{'require': header.require && !isDetail}">{{header.label}}</td>
                <td :class="{'disabled': disabledKeys.includes(header.value)}">
                    <slot v-if="header.slot" :name="header.slot"></slot>
                    <div class="select-container" v-else-if="header.type === 'select'">
                        <el-select v-if="header.multiple === true" size="small"
                            :value="form[header.value] && form[header.value].split(',')"
                            @input="selectInput($event,header.value)"
                            :disabled="isDetail" multiple>
                            <el-option  v-for="item in header.optionList"  :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                        <el-select v-else size="small" v-model="form[header.value]" :disabled="isDetail">
                            <el-option v-for="item in header.optionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </div>
                    <div v-else>
                        <div v-if="isDetail"> {{form[header.value]}} </div>
                        <el-input v-else v-model="form[header.value]" :readonly="isDetail"></el-input>
                    </div>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        isDetail: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: ''
        },
        headers: {
            type: Array,
            default(){
                return [];
            }
        },
        form: {
            type: Object,
            default(){
                return {};
            }
        },
        disabledKeys: {
            type: Array,
            default(){
                return [];
            }
        }
    },
    data() {
        return {
            // isDetail: true
            
        };
    },
    methods: { 
        selectInput(val,key){
            this.$set(this.form,key,val.join(','))
        }
    },
};
</script>

<style lang="less" scoped>
td:nth-child(1){
    width: 15%;
    background-color: #F0F2F5;
}
</style>