<template>
    <div class="table">
        <el-table ref="multipleTable" border :data="tableDatas" @selection-change="handleSelectionChange"
            v-loading="loading" :show-summary="hasStatistic" :max-height="maxHeight" :height="height">
            <el-table-column v-if="selection" type="selection" width="52" key="selection"></el-table-column>
            <el-table-column v-if="hasIndex" type="index" width="50" label="序号" key="index"></el-table-column>
            <template>
                <el-table-column v-for="column in headers" :label="column.label" :key="column.label" :prop="column.value"
                    :show-overflow-tooltip="true">
                    <template slot-scope="{ row , $index }">
                        <slot v-if="column.slot" :name="column.slot" :row="row" :index="$index" />
                        <span v-else-if="column.enumMap">
                            {{column.enumMap[row[column.value]]}}
                        </span>
                        <span v-else>{{ row[column.value] }}</span>
                    </template>
                </el-table-column>
            </template>
        </el-table>
    </div>
</template>

<script>
export default {
    props: {
        headers: {
            type: Array,
            default () {
                return [];
            }
        },
        tableData: {
            type: Array,
            default () {
                return [];
            }
        },
        selectList: {
            type: Array,
            default () {
                return [];
            }
        },
        selection: {
            type: Boolean,
            default: false
        },
        hasIndex: {
            type: Boolean,
            default: false,
        },
        hasStatistic: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        height: [Number,String],
        maxHeight: Number,
        // rowKey: String,      //  有问题，表格打开下级菜单会闪动
    },
    data () {
        return {
        };
    },
    computed: {
        tableDatas () {
			return this.tableData
        },
    },
    beforeUpdate () {
        // 防止列表抖动
        this.$nextTick(() => {
            this.$refs['multipleTable'].doLayout()
        })
    },
    methods: {
        width (data) {
            if (data.type === 'selection') {
                return 72;
            } else {
                return data.width;
            }
        },
        handleSelectionChange (data) {
            this.$emit('update:selectList', data);
        },
        statisticCount (row) {
            let num = 0;
            Object.keys(row).forEach(key => {
                let count = Number(row[key]);
                if (!isNaN(count)) {
                    num += count;
                }

            })
            return num;
        },
    },

};
</script>

<style lang="less" scoped>
.table {
    /deep/ .el-table__header-wrapper {
        th {
            background-color: #EFF3F7;
            color: #101010;
        }
    }

    /deep/ .el-table-column--selection {
        text-align: center;
    }

    /deep/ .el-table__cell {}

}
</style>
<style>
.table-header-cell {}
</style>