<template>
    <el-form ref="form" :model="form" label-width="80px" label-position="top" :rules="rules">
        <el-form-item :label="header.label" v-for="header in headers" :key="header.value" :prop="header.value">
            <slot v-if="header.slot" :name="header.slot" :header="header"></slot>
            <el-input v-else size="small" v-model="form[header.value]"></el-input>
        </el-form-item>
    </el-form>
</template>

<script>
export default {
    props: {
        headers: {
            type: Array,
            default(){
                return [];
            }
        },
        form: {
            type: Object,
            default(){
                return {};
            }
        },
        rules: {
            type: Object,
            default(){
                return {};
            }
        }
    },
    data(){
        return {
            
        }
    },
    methods: {
        submit(){
            return new Promise((resolve,reject) => {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        resolve();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                })

            })
        },
        resetForm() {
            this.$refs.form.resetFields();
        }
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
/deep/ .el-form-item__label{
    line-height: 1;
}
/deep/ .el-select{
    width: 100%;
}
</style>