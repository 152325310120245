<template>
    <div class="base-info">
        <TableForm :template="template" :form="form" :isDetail="isDetail" title="呈报信息">
            <template slot="repostTime">
                <el-date-picker v-model="form.repostTime" type="date" :readonly="isDetail"
                    :pickerOptions="pickerOptions"
                    value-format="yyyy-MM-dd"
                    format="yyyy.MM.dd"></el-date-picker>
            </template>
            <template slot="approvalSignTime">
                <el-date-picker v-model="form.approvalSignTime" type="date" :readonly="isDetail"
                    :pickerOptions="pickerOptions"
                    value-format="yyyy-MM-dd"
                    format="yyyy.MM.dd"></el-date-picker>
            </template>
        </TableForm>
    </div>
</template>

<script>
import TableForm from '@/common/module/tableForm';
import { report , otherReport } from './data';

export default {
    components: {
        TableForm
    },
    props: {
        form: {
            type: Object,
            default(){
                return {};
            }
        },
        isDetail: {
            type: Boolean,
            default: true
        },
        disabledKeys: {
            type: Array,
            default(){
                return [];
            }
        }
    },
    data(){
        return {
            pickerOptions: {
                disabledDate (time) {
                    return time.getTime() > Date.now();
                },
            },
            template: [],
        }
    },
    created(){
        if(this.$getCurrentLib()){
            this.template = otherReport;
        }else{
            this.template = report;
        }
    },
    methods: {

    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>
</style>