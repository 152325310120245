<template>
    <div class="table-form" :class="{'detail': isDetail}">
        <div class="title">{{ title }}</div>
        <table border="1">
            <tr v-for="(header,index) in annexHeaders" :key="index">
                <td>{{header.label}}</td>
                <td>
                    <div class="row">
                        <el-button v-if="!isDetail" type="primary" size="mini" @click="uploadClick(header)">点击上传</el-button>
                        <div class="file" v-for="(file,fileIndex) in annexTableData[header.label]" :key="fileIndex" @click="openFile(file.filePath)" :title="file.fileName">
                            <span class="left">
                                <i class="el-icon-document"></i>
                                <span class="name">{{file.fileName}}</span>
                            </span>
                            <i v-if="!isDetail" class="close iconfont icon-shanchu" @click.stop="delFile(file)"></i>
                        </div>
                    </div>
                </td>
            </tr>
        </table>
        <input ref="inputFile" type="file" v-show="false" @change="inputChange" multiple>
    </div>
</template>

<script>
import { uploadFile } from '@/api/fileAPI.js';
import { Base64 } from 'js-base64';

export default {
    props: {
        isDetail: {
            type: Boolean,
            default: true
        },
        fileList: {
            type: Array,
            default(){
                return [];
            }
        },
    },
    computed: {
        annexHeaders(){
            const res = this.hrmsDir.map(item => {
                return {
                    label: item.label,
                    value: item.id
                }
            })
            return res;
        },
        annexTableData(){
            const form = {}
            this.annexHeaders.forEach(annex => {
                form[annex.label] = (this.fileList || []).filter(file => file.dirName === annex.label);
            })
            
            return form;
        },
    },
    data() {
        return {
            currentName: '',
			title: '',
			hrmsDir: []
        };
    },
	created() {
		const lib = this.$getCurrentLib()
		if(lib) {
			this.title = lib === 'cadre' ? '成长档案' : '培养档案'
		} else {
			this.title = '附件材料'
		}
		this.init()
	},
    methods: { 
		init() {
			const path = this.$route.path;
            if(path.endsWith('/cadre')){
                this.hrmsDir = this.$store.getters.dictMap['hrms_dir_cadre'];
            }else if(path.endsWith('/dean')){
                // return this.$store.getters.dictMap['hrms_dir_dean'];
                const dir = this.$store.getters.dictMap['hrms_dir_dean'];
				const index = dir.findIndex(item => item.label === '基础信息表')
				if(index) {
					dir.splice(1, 0, dir.splice(index, 1)[0])
				}
				this.hrmsDir = dir
            }else{
                this.hrmsDir = this.$store.getters.dictMap['hrms_dir'];
            }
		},
        uploadClick(header){
            this.currentName = header.label;
            this.$refs.inputFile.click();
        },
        inputChange(){
            const files = this.$refs.inputFile.files;
            
			const note = `上传${this.currentName}文件`
	 		const path = '/hrms/file/upload/attachment'
	  		this.$store.commit('status/SET_LOG', {path, note})

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                // console.log(file);
                uploadFile({ file }).then(res => {
                    // console.log(res);
                    this.fileList.push({
                        ...res,
                        dirName: this.currentName
                    });
                    // console.log(this.fileList);
                })
            }
            this.$refs.inputFile.value = '';
        },
        openFile(filePath){
            const fileViewer = this.$store.getters.configMap['fileViewer'];
            const url = encodeURIComponent(Base64.encode(filePath));
            const res = `${fileViewer}/onlinePreview?url=${url}&officePreviewType=pdf`;
            window.open(res,'_blank')
        },
        delFile(file){
            const index = this.fileList.indexOf(file);
            this.fileList.splice(index,1);
        }
    },
    mounted() {}
};
</script>

<style lang="less" scoped>
td:nth-child(1){
    width: 15%;
    background-color: #F0F2F5;
}
td:nth-child(2){
    padding: 10px 10px;
}
.row{
    display: block;
    width: 420px;
    .file{
        height: 26px;
        line-height: 26px;
        color: #606266;
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .left{
            padding-right: 12px;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            .name:hover{
                color: #409eff;
                cursor: pointer;
            }
            i{
                margin-right: 5px;
            }
        }
        .close{
            display: none;
            position: absolute;
            right: 0;
        }
    }
    .file:hover{
        background-color: #f5f7fa;
        .close{
            display: inline-block;
            cursor: pointer;
        }
    }
}
// .row{
//     display: flex;
//     flex-wrap: wrap;
//     .file{
//         height: 30px;
//         display: flex;
//         align-items: center;
//         color: #3291F8;
//         cursor: pointer;
//         position: relative;
//         margin-left: 20px;
//         i{
//             position: absolute;
//             width: 14px;
//             height: 14px;
//             right: 0;
//             top: 0;
//             border: 1px solid #ccc;
//             border-radius: 50%;
//             color: #ccc;
//             font-size: 12px;
//             box-sizing: border-box;
//             transform: translate(50%,0);
//             display: none;

//         }
//     }
//     .file:hover{
//         // background-color: #f5f7fa;
//         i{
//             cursor: pointer;
//             display: inline-block;
//         }
//     }
// }



</style>
